import { useState, useEffect, useRef } from "react";

//react router dom
import { Link, useLocation } from "react-router-dom";

//hooks
import useCustomSearchParams from "../../../hooks/useCustomSearchParams";

//service
import { GetAllAction } from "../../../services/action";

//SVG
import { ReactComponent as Search } from "../../../assets/svg/search.svg";

//Components
import CouncilTableWrapper from "./../../../components/common/tableWrapper";
import SingleActionCard from "../../../components/page/actions/singleaction";

//static data
const tableHeader = [
  {
    title: "#",
    style: "col-span-1",
  },
  {
    title: " عکس",
    style: "col-span-2",
  },
  {
    title: " عنوان",
    style: "col-span-2",
  },
  {
    title: "توضیحات",
    style: "col-span-4",
  },
];

const ActionList = () => {
  //ref
  const searchContainer = useRef();

  //location
  const location = useLocation();

  //custome hooks
  const [searchParams, setSearchParams] = useCustomSearchParams();

  //data
  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState([]);
  const [meta, setMeta] = useState({});

  useEffect(() => {
    //check if have searchParams
    if (searchParams.search) {
      //add value of search to search Container to show user
      searchContainer.current.value = searchParams.search;
    }

    //on search for course after filtering data,
    //check if we have any search params

    //checking that a request has not already been made

    if (isLoading === false) {
      httpGetActionList(location.search.substring(1));
    }
  }, [location]);

  const httpGetActionList = async (filter = "") => {
    setIsLoading(true);
    try {
      const response = await GetAllAction({ filter });
      if (response.status === 200) {
        setAction([...response.data.data]);
        setMeta({
          ...response.data.meta,
        });
      }
    } catch (error) {
      console.log("error in fetching commite list : ", error);
    }
    setIsLoading(false);
  };

  const onSetSearchHandler = () => {
    setSearchParams({
      search: searchContainer.current.value,
    });
  };

  const generateTable = () => {
    return action.length ? (
      <div className="bg-white px-2 rounded-b-md">
        {action.map((singleAction, index) => (
          <SingleActionCard
            key={index}
            index={index}
            singleAction={singleAction}
            lastIndex={action.length - 1}
          />
        ))}
      </div>
    ) : (
      <span className="bg-orange-300 w-fit self-center p-5 rounded-md my-5">
        متاسفانه هیچ داده ای یافت نشد!
      </span>
    );
  };

  return (
    <div className="flex flex-col gap-8 mb-10">
      <Link className="self-end -mt-16" to={"newaction"}>
        <button className="bg-[#435bf1] text-white w-fit self-end py-3 px-4 rounded-md">
          ایجاد فعالیت جدید
        </button>
      </Link>
      <div className="bg-white p-4 rounded-md ">
        <div className="flex items-center w-full border-2 border-[#CBCBCB] rounded-md p-1 pl-5 pr-2 py-2">
          <input
            ref={searchContainer}
            className="w-full outline-none text-lg placeholder:text-base placeholder:text-[#CBCBCB]"
            placeholder="فعالیت مورد نظر خود را جستجو کنید."
          />
          <button onClick={onSetSearchHandler}>
            <Search className="text-[#717171]" />
          </button>
        </div>
      </div>
      <CouncilTableWrapper
        meta={meta}
        tableHeader={tableHeader}
        minSize="min-w-[900px]"
      >
        {isLoading ? <></> : generateTable()}
      </CouncilTableWrapper>
    </div>
  );
};

export default ActionList;
