import { useEffect, useState } from "react";

//react router dom
import { Link, useParams } from "react-router-dom";

//service
import { GetSingleCourse } from "../../../../services/educationalCourse";
//svg
import { ReactComponent as Back } from "../../../../assets/svg/backward.svg";
import { toast } from "react-toastify";

const SingleCourse = () => {
  const { id } = useParams();

  //data
  const [singleCourse, setSingleCourse] = useState({});

  useEffect(() => {
    httpGetSingleCourse();
  }, []);

  const httpGetSingleCourse = async () => {
    try {
      const response = await GetSingleCourse({ id });

      if (response.status === 200) {
        setSingleCourse({
          ...response.data.data,
        });
      } else {
        toast.error("دریافت دوره تکی ناموفق بود");
      }
    } catch (error) {
      console.log("error in getting single course : ", error);
    }
  };

  return (
    <div className="flex flex-col mb-10">
      <Link className="self-end" to={"/educationalCourses"}>
        <button className="flex items-center  gap-2 bg-[#435bf1] text-white -mt-16 w-fit py-3 px-4 rounded-md">
          <Back />
          بازگشت
        </button>
      </Link>
      <div className="flex flex-col gap-8 bg-white p-8 rounded-md">
        <img
          src={singleCourse.banner}
          alt="banner"
          className="bg-slate-400 w-full h-[350px] rounded-sm object-fill"
        />
        <div className="flex flex-col items-start gap-7">
          <div className="flex flex-col items-start gap-1">
            <span className="">عنوان دوره</span>
            <p className="text-lg font-medium">{singleCourse.title}</p>
          </div>
          <div className="flex flex-col items-start gap-1">
            <span className="">درباره دوره</span>
            <p className="text-lg font-medium">{singleCourse.body}</p>
          </div>
          <div className="flex items-end justify-between gap-2 flex-wrap w-full">
            <div className="flex flex-col items-center gap-1">
              <span>سطح</span>
              <span className="text-xl font-medium">{singleCourse.level}</span>
            </div>
            <div className="flex flex-col items-center gap-1">
              <span>استاد</span>
              <span className="text-xl font-medium">{singleCourse.author}</span>
            </div>
            <div className="flex flex-col items-center gap-1">
              <span>وضعیت</span>
              <span className="text-xl font-medium">{singleCourse.status}</span>
            </div>
          </div>
          <div className="flex items-end justify-between gap-2 flex-wrap w-full">
            <div className="flex flex-col items-center gap-1">
              <span>قیمت دوره</span>
              <span className="text-xl font-medium">{singleCourse.price}</span>
            </div>
            <div className="flex flex-col items-center gap-1">
              <span>مقدار تخفیف</span>
              <span className="text-xl font-medium">
                {singleCourse.discount_price}
              </span>
            </div>
            <div className="flex flex-col items-center gap-1">
              <span>زمان دوره</span>
              <span className="text-xl font-medium">
                {singleCourse.duration}
              </span>
            </div>
          </div>
          <div>
            <span>تگ ها</span>
            <div className="flex items-center justify-start gap-1 text-lg font-medium">
              {singleCourse.tags !== undefined &&
                singleCourse.tags
                  .split(",")
                  .map((singleTag, index) => (
                    <span key={index}>{singleTag} ,</span>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCourse;
