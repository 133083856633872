import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  data: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.isLoading = false;

      state.data = { ...action.payload };
    },
    changeLoadingStatus: (state, action) => {
      //change value of is loading to what is sent to user
      state.isLoading = action.payload.isLoading;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeLoadingStatus, addUser } = userSlice.actions;

export default userSlice.reducer;
