import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import moment from "moment-jalaali";

const CharityDatePicker = ({

  placeholder,
  onChangeHandler,
  target,
  value,
}) => {
  moment.loadPersian({ usePersianDigits: false });

  return (
      <DatePicker
      className="w-full"
        value={moment(value)._d}
        placeholder={placeholder}
        onChange={(e) =>
          onChangeHandler(
            target,
            moment(e.toDate?.().toString()).format("YYYY/MM/DD")
          )
        }
        calendar={persian}
        locale={persian_fa}
      />
  );
};

export default CharityDatePicker;
