import useFetch from "../hooks/useFetch";
const endPoint = "news";

export async function GetAllNews({ filter }) {
  const apiCall = await useFetch().get(`${endPoint}?${filter}`);
  return apiCall;
}

export async function DeleteSingleNews({ id }) {
  const apiCall = await useFetch().delete(`${endPoint}/${id}`);

  return apiCall;
}

export async function GetSingleNews({ id }) {
  const apiCall = await useFetch().get(`${endPoint}/${id}`);

  return apiCall;
}

export async function CreateNewNews({ title, description, image }) {
  const formData = new FormData();

  formData.append("title", title);
  formData.append("description", description);
  formData.append("image", image);

  const apiCall = await useFetch().post(`${endPoint}`, formData);

  return apiCall;
}

export async function EditNews({ id, description, title }) {
  
  const data = {
    "title" : title,
    "description" : description,
  }

  const apiCall = await useFetch().put(`${endPoint}/${id}`, data);

  return apiCall;
}

export async function EditImage({ id , image }){
  const formData = new FormData();
  formData.append("image" , image);

  const apiCall = await useFetch().post(`${endPoint}/image/${id}`);

  return apiCall

}

export async function DeleteNewsBanner({ id }) {
  const apiCall = await useFetch().delete(`${endPoint}/image/${id}`);

  return apiCall;
}
