import PersonalInformation from "./personalinformation";
import PhoneInformation from "./phoneinformation";

const Setting = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-8">
      <PersonalInformation />
      <PhoneInformation />
    </div>
  );
};

export default Setting;
