import useFetch from "../hooks/useFetch";

const endPoint = "courses";

export async function GetAllCourse({ filter }) {
  const apiCall = await useFetch().get(`${endPoint}?${filter}`);
  return apiCall;
}

export async function DeleteSingleCourse({ id }) {
  const apiCall = await useFetch().delete(`${endPoint}/${id}`);

  return apiCall;
}

export async function GetSingleCourse({ id }) {
  const apiCall = await useFetch().get(`${endPoint}/${id}`);

  return apiCall;
}

export async function CreateNewCourse({
  title,
  price,
  status,
  body,
  author,
  banner,
}) {
  const formData = new FormData();

  formData.append("title", title);
  formData.append("price", price);
  formData.append("status", status);
  formData.append("body", body);
  formData.append("author", author);
  formData.append("banner", banner);

  const apiCall = await useFetch().post(`${endPoint}`, formData);

  return apiCall;
}

export async function EditCourse({
  id,
  title,
  price,
  status,
  body,
  author,
}) {
  const data = {
    "title" : title,
    "price" : price, 
    "status" : status,
    "body" : body,
    "author" : author,
  }

  const apiCall = await useFetch().put(`${endPoint}/${id}`, data);

  return apiCall;
}

export async function EditImage({ id , banner }){
  const formDate = new FormData();

  formDate.append("banner" , banner);

  const apiCall = await useFetch().post(`${endPoint}/image/${id}`,formDate);

  return apiCall;
}

export async function DeleteCourseBanner({ id }) {
  const apiCall = await useFetch().delete(`${endPoint}/image/${id}`);

  return apiCall;
}
