import React, { useEffect, useState } from "react";

//react router dom
import { Link, useNavigate } from "react-router-dom";
import useCustomSearchParams from "../../../../hooks/useCustomSearchParams";

import { ReactComponent as Pdf } from "../../../../assets/svg/pdf.svg";

//service
import {
  CreateNewAction,
  GetSingleAction,
  EditAction,
  DeleteActionBanner,
  DeleteActionFile,
  EditImage,
  EditFile,
} from "../../../../services/action";

//component
import CharityTextEditor from "../../../../components/common/charityTextEditor";
import CharityDropFile from "../../../../components/common/dropFile";

//SVG
import { ReactComponent as Back } from "../../../../assets/svg/backward.svg";
import { toast } from "react-toastify";

const AddNewAction = () => {
  //navigate
  const navigate = useNavigate();

  const [searchParams] = useCustomSearchParams();

  //data
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [dataSchema, setDataSchema] = useState({});

  const [image, setImage] = useState();

  const [file, setFile] = useState();

  useEffect(() => {
    //check if we are in edit mode
    if (searchParams.status === "edit") {
      httpGetSingleAction();
    }
  }, []);

  const onSetDataSchemaHandler = (target, value) => {
    setDataSchema((prevState) => ({
      ...prevState,
      [target]: value,
    }));
  };

  const httpGetSingleAction = async () => {
    try {
      const response = await GetSingleAction({ id: searchParams.id });

      if (response.status === 200) {
        setDataSchema({
          ...response.data.data,
        });
      }
    } catch (error) {
      console.log("error in get signle action : ", error);
    }
  };

  const httpCreateNewAction = async () => {
    try {
      setButtonDisabled(true);
      if (dataSchema.title === undefined || dataSchema.caption === undefined || dataSchema.short === undefined) {
        console.log(dataSchema.title);

        toast.error("اضافه کردن فعالیت ناموفق بود");
        setButtonDisabled(false);
        return
      }
      const response = await CreateNewAction({
        title: dataSchema.title,
        caption: dataSchema.caption,
        image,
        file,
        short: dataSchema.short,

      });

      if (response.status === 200) {
        setButtonDisabled(false)
        navigate("/action");
      } else {
        setButtonDisabled(false)
        toast.error("اضافه کردن فعالیت ناموفق بود");
      }
    } catch (error) {
      console.log("error in create new action : ", error);
    }
  };

  const httpEditAction = async () => {
    console.log(image);
    try {
      setButtonDisabled(true)
      if (dataSchema.title === undefined || dataSchema.caption === undefined || dataSchema.short === undefined) {
        console.log(dataSchema.title);
        toast.error("ویرایش کردن فعالیت ناموفق بود");
        setButtonDisabled(false);
        return
      }
      const response = await EditAction({
        id: searchParams.id,
        title: dataSchema.title,
        caption: dataSchema.caption,
        short: dataSchema.short,
      });

      if (image !== undefined) {
        await EditImage({
          id: searchParams.id,
          image,
        });
      }
      if (file !== undefined) {
        await EditFile({
          id: searchParams.id,
          file
        });
      }

      if (response.status === 200) {
        setButtonDisabled(false)
        navigate("/action");
      } else {
        setButtonDisabled(false)
        toast.error("ویرایش کردن فعالیت ناموفق بود");
      }
    } catch (error) {
      console.log("error in edit new action : ", error);
    }
  };

  const httpDeleteBanner = async () => {
    try {
      setButtonDisabled(true)
      const response = await DeleteActionBanner({ id: searchParams.id });

      if (response.status === 200) {
        setButtonDisabled(false)
        toast.success("بنر فعالیت با موفقیت حذف شد");
        navigate(0);
      } else {
        setButtonDisabled(false)
        toast.error("حذف بنر موفقیت آمیز نبود");
      }
    } catch (error) {
      console.log("error in delete Banner : ", error);
    }
  };

  const httpDeleteFile = async () => {

    try {
      setButtonDisabled(true)
      const response = await DeleteActionFile({ id: searchParams.id });

      if (response.status === 200) {
        setButtonDisabled(false)
        toast.success("فایل فعالیت با موفقیت حذف شد");
        navigate(0);
      } else {
        setButtonDisabled(false)
        toast.error("حذف فایل موفقیت آمیز نبود");
      }
    } catch (error) {
      console.log("error in delete file : ", error);
    }
  }
  return (
    <div className="flex flex-col">
      <Link className="self-end" to={"/action"}>
        <button className="flex items-center  gap-2 bg-[#435bf1] text-white -mt-16 w-fit py-3 px-4 rounded-md">
          <Back />
          بازگشت
        </button>
      </Link>
      <div className="flex flex-col gap-8 bg-white p-8 rounded-md">
        <span className="font-bold">ایجاد فعالیت جدید</span>
        <div className="flex flex-col relative">
          <span className="absolute bg-white -top-3 right-2 px-3">عنوان</span>
          <input
            value={dataSchema.title}
            onChange={(e) => onSetDataSchemaHandler("title", e.target.value)}
            placeholder="عنوان فعالیت جدید رو اینجا بنویس!"
            className="border-2 border-[#CBCBCB] p-2 rounded-md "
            required={true}
          />
        </div>
        <div className="flex flex-col relative">
          <span className="absolute bg-white -top-3 right-2 px-3">
            توضیح کوتاه برای فعالیت
          </span>
          <input
            value={dataSchema.short}
            onChange={(e) =>
              onSetDataSchemaHandler("short", e.target.value)
            }
            placeholder="یک توضیح کوتاه برای فعالیت وارد کنید"
            className="border-2 border-[#CBCBCB] p-2 rounded-md "
            required
          />
        </div>
        <div className="flex flex-col relative">
          <CharityTextEditor
            title={"caption"}
            value={dataSchema.caption}
            getValue={onSetDataSchemaHandler}
          />
        </div>

        {dataSchema.image ? (
          <div>
            <span className="">
              تصویری برای فعالیت
            </span>
            <button
              onClick={httpDeleteBanner}
              disabled={buttonDisabled}
              className="px-7 py-2 text-white font-medium bg-red-700 rounded-md mb-5"
            >
              حذف عکس
            </button>
            <img
              className="h-[300px] w-full object-contain"
              src={dataSchema.image}
              alt="banner"
            />
          </div>
        ) : (
          <div>
            <span className="">
              تصویری برای فعالیت
            </span>
            <CharityDropFile
              data={image}
              setData={(e) => setImage(e)}
              acceptableType={"image/png, image/gif, image/jpeg"}
              isArray={false}
              isImage={true}
            />
          </div>
        )}

        {dataSchema.attachment ? (
          <div>
            <span className="">
              فایلی برای فعالیت
            </span>
            <button
              onClick={httpDeleteFile}
              disabled={buttonDisabled}
              className="px-7 py-2 text-white font-medium bg-red-700 rounded-md mb-5"
            >
              حذف فایل
            </button>
            <div className="m-10 card bg-white shadow-xl p-5 ">
              <h1 className="font-bold text-xl mb-5 "> فایل ها</h1>
              <a
                className="cursor-pointer flex gap-3 items-center hover:text-blue-600"
                title="download" href={dataSchema.attachment}
              >
                <Pdf className="w-8 h-8" />
                فایل پیوست
              </a>
            </div>
          </div>
        ) : (
          <div>
            <span>
              فایلی  برای فعالیت
            </span>
            <CharityDropFile
              data={file}
              setData={(e) => setFile(e)}
              acceptableType={"file/pdf, file/docx"}
              isArray={false}
              isImage={false}
            />
          </div>
        )}

        {searchParams.status === "edit" ? (
          <button
            onClick={httpEditAction}
            disabled={buttonDisabled}
            className="px-8 py-2 rounded-md bg-blue-700 border-2 border-blue-700 text-white hover:text-blue-700 hover:bg-white duration-200 w-fit mr-auto"
          >
            ویرایش کردن
          </button>
        ) : (
          <button
            onClick={httpCreateNewAction}
            disabled={buttonDisabled}
            className="px-8 py-2 rounded-md bg-blue-700 border-2 border-blue-700 text-white hover:text-blue-700 hover:bg-white duration-200 w-fit mr-auto"
          >
            اضافه کردن
          </button>
        )}
      </div>
    </div>
  );
};

export default AddNewAction;
