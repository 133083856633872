import useFetch from './../hooks/useFetch';

export async function GetProvincesListHttp() {
  const apiCall = await useFetch().get(`https://api.inabadi.ir/api/provinces`);
  return apiCall;
}

export async function GetCitiesListHttp({ provinceId }) {
  const apiCall = await useFetch().get(`https://api.inabadi.ir/api/cities/${provinceId}`);
  return apiCall;
}
