import { useState } from "react";

import { VerifyPhoneNumber } from "../../../../services/auth";

import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import useTimer from "../../../../hooks/useTimer";
import { useEffect } from "react";
import { toast } from "react-toastify";

function VerifyPhone({ userPhonNumber, onChangeStatusHandler }) {
  const [cookies, setCookies] = useCookies(["token", "admin"]);
  const navigate = useNavigate();
  const timer = useTimer(120);

  const [verifyCode, setVerifyCode] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    //timer is zero , return to get phone number
    if (timer.seconds == 0 && timer.minutes == 0) {
      onChangeStatusHandler(0);
    }
  }, [timer]);

  const onChangeVerifyCodeHandler = (value) => {
    if (value.trim().length > 4) {
      return;
    }

    if (!/^\d{4}$/.test(value)) {
      setError(true);
    } else {
      setError(false);
    }

    setVerifyCode(value);
  };

  const onVerifyPhoneHandler = async () => {
    //check if there is error
    if (error) return;

    //check the length of phone number
    if (verifyCode.trim().length === 0) {
      //there is no phone number, set error
      setError(true);
      return;
    }

    try {
      const response = await VerifyPhoneNumber({
        phoneNumber: userPhonNumber,
        code: verifyCode,
      });

      //check response status
      if (response.status === 200) {
        //set cookie of token
        setCookies("token", response.data.token, {
          httpOnly: false,
        });
        //set cookie of user
        setCookies("admin", JSON.stringify(response.data.user), {
          httpOnly: false,
        });

        //navigate to dashboard
        navigate("/dashboard");
      } else if (response.status === 400) {
        toast.error("شما ادمین نیستید");
      }
    } catch (error) {
      console.log("error : ", error);
    }
  };

  return (
    <>
      <div className="my-5 flex flex-col gap-2 w-full">
        <div className="flex flex-col items-start gap-1 w-3/4 mx-auto">
          <label className="font-medium">بررسی کد</label>
          <span className="text-sm text-gray-600">
            کد برای شماره ({userPhonNumber}) ارسال شده
          </span>
          <input
            dir="ltr"
            value={verifyCode}
            onChange={(e) => onChangeVerifyCodeHandler(e.target.value)}
            placeholder="کد ارسال شده را وارد کنید"
            className={`placeholder:text-sm text-2xl text-center border-2 rounded-md outline-none px-3 py-2 w-full ${
              error ? "border-red-700" : ""
            }`}
          />
          {error && (
            <span className="text-sm text-red-700">
              لطفا کد را به درستی وارد کنید
            </span>
          )}
        </div>
        <div className="w-3/4 mx-auto">
          <span className="text-sm font-medium text-gray-500">
            تا ارسال کد {timer.seconds} : {timer.minutes} مهلت دارید
          </span>
        </div>
      </div>
      <div className="flex items-center justify-between gap-5 w-full">
        <button
          onClick={onVerifyPhoneHandler}
          className="grow w-full py-2 bg-blue-700 border-blue-700 border-2 text-white hover:text-blue-700 hover:bg-white duration-200 rounded-md"
        >
          ثبت
        </button>
        <button
          onClick={() =>
            //return to get phone number
            onChangeStatusHandler(0)
          }
          className="grow w-full py-2 bg-gray-700 border-gray-700 border-2 text-white hover:text-gray-700 hover:bg-white duration-200 rounded-md"
        >
          انصراف
        </button>
      </div>
    </>
  );
}

export default VerifyPhone;
