import { useState } from 'react';
import { LoginUserName } from '../../../../services/auth';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const LoginForm = ({ onChangeStatusHandler }) => {
  const [cookies, setCookies] = useCookies(['token', 'admin']);
  const navigate = useNavigate();

  const [dataSchema, setDataSchema] = useState({
    userName: '',
    password: '',
  });

  const onChangeDataSchemaHandler = (target, value) => {
    setDataSchema((prevState) => ({
      ...prevState,
      [target]: value,
    }));
  };

  const onLoginSubmit = async () => {
    if (dataSchema.userName.trim().length !== 10) {
      toast.error('طول کد ملی باید 10 رقم باشد');
    } else if (dataSchema.password.trim().length === 0) {
      toast.error('رمز عبور را باید وارد کنید');
    }

    try {
      const response = await LoginUserName({ ...dataSchema });

      if (response.status === 200) {
        //set cookie of token
        setCookies('token', response.data.token, {
          httpOnly: false,
        });
        //set cookie of user
        setCookies('admin', JSON.stringify(response.data.user), {
          httpOnly: false,
        });

        //navigate to dashboard
        navigate('/dashboard');
      } else {
        if (response.data.error) {
          toast.error(response.data.error);
        } else {
          toast.error('خطایی در ورود به پنل رخ داده');
        }
      }
    } catch (error) {
      console.log('error in login : ', error);
    }
  };

  return (
    <div className="flex flex-col gap-y-7 w-full my-5">
      <div className="flex flex-col relative">
        <span className="absolute bg-white -top-3 right-2 px-3">کدملی</span>
        <input
          value={dataSchema.userName}
          onChange={(e) => onChangeDataSchemaHandler('userName', e.target.value)}
          placeholder="کدملی خود را وارد کنید . . ."
          className="border-2 border-[#CBCBCB] p-2 rounded-md "
          required={true}
        />
      </div>
      <div className="flex flex-col relative">
        <span className="absolute bg-white -top-3 right-2 px-3">رمز عبور</span>
        <input
          value={dataSchema.password}
          onChange={(e) => onChangeDataSchemaHandler('password', e.target.value)}
          placeholder="رمز عبور خود را وارد کنید"
          className="border-2 border-[#CBCBCB] p-2 rounded-md "
          required={true}
        />
      </div>
      <div className="flex flex-col w-full gap-y-2">
        <button
          onClick={onLoginSubmit}
          className="w-full py-2 bg-blue-700 border-blue-700 border-2 text-white hover:text-blue-700 hover:bg-white duration-200 rounded-md"
        >
          ورود
        </button>
        <button className="text-blue-700 text-sm font-medium" onClick={() => onChangeStatusHandler(0)}>
          ورود با شماره تلفن
        </button>
      </div>
    </div>
  );
};

export default LoginForm;
