import { useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

const CouncilTextEditor = ({ title, getValue, defaultValue, value }) => {
  const editorRef = useRef(null);
  return (
    <Editor
      value={value}
      onEditorChange={(e) => getValue(title, e)}
      initialValue={defaultValue && defaultValue}
      apiKey="ks5rz8rj0hfasnxzwshkic29c69nj3bueh0fef52kmrtoz8z"
      onInit={(evt, editor) => (editorRef.current = editor)}
      init={{
        height: 500,
        menubar: false,
        plugins: [
          "print preview powerpaste code codesample searchreplace autolink directionality visualblocks visualchars fullscreen image link media pageembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help",
        ],
        toolbar:
          "formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image media pageembed | alignleft aligncenter alignright alignjustify rtl ltr | numlist bullist outdent indent | removeformat | addcomment code codesample",
        images_upload_url: "/panel/upload-image",
        relative_urls: false,
        convert_urls: true,
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      }}
    />
  );
};
export default CouncilTextEditor;
