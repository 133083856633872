import React, { useState } from 'react';
//react-router-dom
import { Outlet } from 'react-router-dom';
//component
import Sidebar from './sidebar';

//pic
// import User from "./../../../assets/image/profile.png";
//SVG
import { ReactComponent as NotificationBing } from './../../../assets/svg/notification-bing.svg';
import { ReactComponent as Support } from './../../../assets/svg/support.svg';
import { ReactComponent as Sun } from './../../../assets/svg/sun.svg';
// import { ReactComponent as AlarmSvg } from "./../../../assets/svg/notification-bing.svg";

const Layout = () => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  return (
    <>
      <div
        className={`z-50 w-full h-full ${
          isSideBarOpen ? 'fixed' : 'hidden'
        } backdrop-filter bg-opacity-70 bg-black backdrop-blur-sm transition-all duration-300 ease-in-out lg:hidden`}
        onClick={() => setIsSideBarOpen(false)}
      ></div>
      <main className="grid lg:grid-cols-12 grid-cols-1">
        <Sidebar sidebarOpen={isSideBarOpen} setSidebarOpen={setIsSideBarOpen} />
        <section className="col-span-12 xl:col-span-10 lg:col-span-9 pl-4 xl:pl-9 pt-7 bg-white">
          <div className="flex flex-col w-full min-h-screen h-full">
            {/* header of main body */}
            <div className="text-white bg-black rounded-t-3xl p-4 w-full flex flex-col gap-y-3 sm:flex-row items-start sm:items-center justify-between">
              <div className="flex flex-col items-center justify-start gap-3 w-full">
                {/* <i className="hidden lg:block w-1.5 h-[25px] bg-[#0172DE]" /> */}
                <button
                  onClick={() => setIsSideBarOpen(true)}
                  className="lg:hidden w-fit text-xl font-medium bg-blue-700 border-2 border-blue-700 text-white hover:text-blue-700 hover:bg-white duration-200 px-3 py-1 rounded-md flex items-center justify-start gap-1"
                >
                  {/* <DashboardSvg /> */}
                  منو
                </button>
                <div className="flex justify-end gap-10 w-full text-[#65656D] mt-3 ml-5">
                  <div className="flex items-center gap-2">
                    <NotificationBing />
                    <span>اطلاعیه ها</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <Support />
                    <span>پشتیبانی</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <Sun />
                  </div>
                </div>
                <div className="flex flex-col w-full my-10 gap-5">
                  <span className="font-extrabold text-4xl">داشبورد من</span>
                  <span className="text-[#65656D]">حساب کاربری / داشبورد من</span>
                </div>
              </div>
            </div>

            <div className="bg-[#F2F2F2] px-5 pt-10 h-full">
              {/* the main part of page :) */}
              <Outlet />
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Layout;
