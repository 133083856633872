import React from "react";
import InfoMassage from "../../../components/page/massagePanel/infomassage";
import SendMassage from "../../../components/page/massagePanel/sendmassage";

const MessagePanel = () => {
  return (
    <div className="flex flex-col items-center">
      <InfoMassage />
      <SendMassage />
    </div>
  );
};

export default MessagePanel;
