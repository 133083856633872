import useFetch from "../hooks/useFetch";
// const endPoint = "news";

export async function GetPhoneNumber() {
  const apiCall = await useFetch().get(`phones`);
  return apiCall;
}

export async function CreatePhoneNumberMessage({ body, phone }) {
  const apiCall = await useFetch().post(`smspanel`, {
    body,
    phone,
  });
  return apiCall;
}
