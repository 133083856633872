//SVG
import { useState } from "react";
import { ReactComponent as Tik } from "../../../../assets/svg/tik.svg";
const PersonalInformation = () => {
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const data = {
        name: "علی محجوب",
        nationalnumber: 12345678,
        birthdate: " 1 / 1 / 1380",
        gender: "مرد",
        phone: "09181233254",
        email: "ali@gmail.com",
        ostan: "همدان",
        city: "تویسرکان",
        adres: "",
    };
    return ( <
        div className = "w-11/12 flex flex-col gap-10" >
        <
        div className = "flex flex-col gap-8  bg-white p-8 rounded-md" >
        <
        span className = "font-bold " > اطلاعات فردی < /span> <
        div className = "grid grid-cols-2 gap-10" >
        <
        div className = "" >
        <
        div className = "flex gap-5 border-2 px-4 py-3 rounded-md border-[#F3F3F3]" >
        <
        span className = "text-[#AFAFAF]" > نام و نام‌خانوادگی < /span> <
        span className = "font-bold" > { data.name } < /span> <
        /div> <
        span className = "flex mt-4 text-[#AFAFAF]" >
        <
        Tik className = "w-10 mt-2" / >
        نام و نام خانوادگی بایستی به زبان فارسی و دقیقا مطابق با نام و نام خانوادگی درج شده شما در کارت ملی به صورت کامل وارد شود <
        /span> <
        /div> <
        div className = "flex h-fit gap-5 border-2 px-4 py-3 rounded-md border-[#F3F3F3] " >
        <
        span className = "text-[#AFAFAF]" > کد ملی < /span> <
        span className = "font-bold" > { data.nationalnumber } < /span> <
        /div> <
        div className = "flex gap-5 border-2 px-4 py-3 rounded-md border-[#F3F3F3] " >
        <
        span className = "text-[#AFAFAF]" > تاریخ تولد < /span> <
        span className = "font-bold" > { data.birthdate } < /span> <
        /div> <
        div className = "flex gap-5 border-2 px-4 py-3 rounded-md border-[#F3F3F3] " >
        <
        span className = "text-[#AFAFAF]" > جنسیت < /span> <
        span className = "font-bold" > { data.gender } < /span> <
        /div> <
        /div> <
        button onClick = {
            () => setIsOpenEdit(true) }
        className = "bg-[#435bf1] w-fit self-end py-3 px-4 rounded-md" >
        ویرایش اطلاعات <
        /button> <
        /div> <
        div > {
            isOpenEdit ? ( <
                div className = "flex justify-center items-center bg-[#1b1a1a93] w-screen h-screen fixed top-0 left-0" >
                <
                div className = "flex flex-col w-11/12 gap-8  bg-white p-8 rounded-md" >
                <
                span className = "font-bold " > ویرایش اطلاعات فردی < /span> <
                div className = "grid grid-cols-2 gap-10" >
                <
                div className = "" >
                <
                div className = "flex flex-col gap-2 px-4" >
                <
                span className = "text-[#AFAFAF]" > نام و نام‌خانوادگی < /span> <
                input className = "font-bold py-2 border-2 rounded-md"
                value = { data.name }
                /> <
                /div> <
                span className = "flex mt-4 text-[#AFAFAF]" >
                <
                Tik className = "w-10 mt-2" / >
                نام و نام خانوادگی بایستی به زبان فارسی و دقیقا مطابق با نام و نام خانوادگی درج شده شما در کارت ملی به صورت کامل وارد شود <
                /span> <
                /div> <
                div className = "flex flex-col h-fit gap-2 px-4" >
                <
                span className = "text-[#AFAFAF]" > کد ملی < /span> <
                input className = "font-bold py-2 border-2 rounded-md"
                value = { data.nationalnumber }
                /> <
                /div> <
                div className = "flex flex-col gap-2 px-4  " >
                <
                span className = "text-[#AFAFAF]" > تاریخ تولد < /span> <
                input className = "font-bold py-2 border-2 rounded-md"
                value = { data.birthdate }
                /> <
                /div> <
                div className = "flex flex-col gap-2 px-4 " >
                <
                span className = "text-[#AFAFAF]" > جنسیت < /span> <
                input className = "font-bold py-2 border-2 rounded-md"
                value = { data.gender }
                /> <
                /div> <
                /div> <
                div className = "flex justify-between" >
                <
                button onClick = {
                    () => setIsOpenEdit(false) }
                className = "border-2 border-[#435bf1] text-[#435bf1] w-fit self-end py-3 px-4 rounded-md" >
                بستن <
                /button> <
                button className = "bg-[#435bf1] text-white w-fit self-end py-3 px-4 rounded-md" >
                ذخیره تغییرات <
                /button> <
                /div> <
                /div> <
                /div>
            ) : ( <
                > < />
            )
        } <
        /div> <
        /div>
    );
};

export default PersonalInformation;