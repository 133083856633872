import { useState } from 'react';
import Modal from '../../../common/modalWrapper';

//component
import { RemoveSingleUser } from '../../../../services/user';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function SingleUserCard({ index, singleUser }) {
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigate();

  const onRemoveUserHandler = async () => {
    if (isLoading) return;

    setIsLoading(true);

    try {
      const response = await RemoveSingleUser({ id: singleUser.id });
      if (response.status === 200) {
        toast.success('کابر با موفقیت حذف شد');
        navigation(0);
      } else {
        toast.error('حذف کاربر ناموفق بود');
      }
    } catch (error) {
      console.log('error : ', error);
    }

    setIsLoading(false);
  };

  return (
    <>
      <tr className="grid grid-cols-12 w-full text-center bg-white p-4 border-b font-medium text-[#74787C] gap-5">
        <td className="col-span-1">{index + 1}</td>
        <td className="col-span-2">{singleUser.first_name}</td>
        <td className="col-span-1">{singleUser.last_name}</td>
        <td className="col-span-2">{singleUser.national_code}</td>
        <td className="col-span-1">{singleUser.phone_number}</td>
        <td className="col-span-2">{singleUser.user_type[0] == 'humanResource' ? 'نیروی انسانی' : 'نیروی مالی'}</td>
        <td className="col-span-3 flex items-center justify-around gap-x-1 flex-wrap">
          <button
            onClick={() => setIsShowDeleteModal(true)}
            className="duration-200 px-4 py-2 hover:border-[#ED2E2E] hover:text-[#ED2E2E] text-[#717171] text-sm border-2 rounded-md"
          >
            حذف
          </button>
          <Link
            to={`/users/changeuser?status=edit&id=${singleUser.id}`}
            className="duration-200 px-4 py-2 hover:border-[#F4B740] hover:text-[#F4B740] text-[#717171] text-sm border-2 rounded-md"
          >
            ویرایش
          </Link>
        </td>
      </tr>
      {isShowDeleteModal && (
        <Modal
          text={`آیا از حذف کاربر ${singleUser.Name} مطمئن هستید؟`}
          isShowModal={isShowDeleteModal}
          onCloseModal={() => setIsShowDeleteModal(false)}
        >
          <div>
            <span>
              آیا از حذف کابر "{singleUser.first_name} {singleUser.last_name}" مطمئن هستید ؟
            </span>
            <div className="mt-5 flex items-center justify-center gap-x-5 w-full">
              <button
                onClick={onRemoveUserHandler}
                className="duration-200 px-4 py-2 hover:border-[#ED2E2E] hover:text-[#ED2E2E] text-[#717171] text-sm border-2 rounded-md"
              >
                حذف
              </button>
              <button
                onClick={() => setIsShowDeleteModal(false)}
                className="duration-200 px-4 py-2 hover:border-gray-400 hover:text-gray-400 text-[#717171] text-sm border-2 rounded-md"
              >
                انصراف
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
