import React from 'react';
import { useState, useEffect } from 'react';

//component
import Card from './card';
//svg
import { ReactComponent as ChartSvg } from './../../../assets/svg/chart.svg';
import { GetStatic } from '../../../services/dashboard';

const Dashboard = () => {
  const [dash, setDash] = useState([]);
  useEffect(() => {
    httpGetStatic();
  }, []);

  const httpGetStatic = async () => {
    const response = await GetStatic();
    if (response.status === 200) {
      setDash([
        {
          id: 1,
          title: 'نیروی انسانی',
          number: response.data.data.num_human,
          icon: <ChartSvg className="w-full" />,
        },
        {
          id: 2,
          title: 'نیروی مالی',
          number: response.data.data.num_financial,
          icon: <ChartSvg className="w-full" />,
        },
        {
          id: 3,
          title: 'کمیته',
          number: response.data.data.num_comm,
          icon: <ChartSvg className="w-full" />,
        },
      ]);
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 flex-wrap">
      {dash.map(({ id, title, number, icon }) => {
        return <Card key={id} title={title} number={number} svg={icon} />;
      })}
    </div>
  );
};

export default Dashboard;
