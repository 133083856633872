import { useEffect, useState } from "react";

//react router dom
import { Link, useParams } from "react-router-dom";

//service
import { GetSingleNews } from "../../../../services/news";

//svg
import { ReactComponent as Back } from "../../../../assets/svg/backward.svg";
import { toast } from "react-toastify";

const SingleNews = () => {
  const { id } = useParams();

  //data
  const [singleNews, setSingleNews] = useState({});

  useEffect(() => {
    httpGetSingleNews();
  }, []);

  const httpGetSingleNews = async () => {
    try {
      const response = await GetSingleNews({ id });

      if (response.status === 200) {
        setSingleNews({
          ...response.data.data,
        });
      } else {
        toast.error("دریافت اخبار ی تکی ناموفق بود");
      }
    } catch (error) {
      console.log("error in getting single news : ", error);
    }
  };

  return (
    <div className="flex flex-col mb-10">
      <Link className="self-end" to={"/news"}>
        <button className="flex items-center  gap-2 bg-[#435bf1] text-white -mt-16 w-fit py-3 px-4 rounded-md">
          <Back />
          بازگشت
        </button>
      </Link>
      <div className="flex flex-col gap-8 bg-white p-8 rounded-md">
        <img
          src={singleNews.image}
          alt="banner"
          className="bg-slate-400 w-full h-[500px] rounded-sm object-fill"
        />
        <div>
          <span className="text-[#717171] font-bold px-2">عنوان خبر :</span>
          <span className="font-bold text-xl">{singleNews.title}</span>
        </div>
        <div className="flex flex-col ">
          <span className="text-[#717171] font-bold px-2"> توضیحات :</span>
          <p
            className="leading-10 text-[#353535] font-medium"
            dangerouslySetInnerHTML={{ __html: singleNews.description }}
          ></p>
        </div>
      </div>
    </div>
  );
};

export default SingleNews;
