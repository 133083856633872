import React, { useState, useRef } from "react";

//helper
import { formatFileSize } from "../../../helper/formatFileSize";

//svg
import { ReactComponent as CloseSvg } from "./../../../assets/svg/close.svg";

const CharityDropFile = ({
  data,
  setData,
  isImage,
  isArray,
  onDeleteSingleFile,
  acceptableType,
  title,
}) => {
  const fileInputContainer = useRef();
  const [dragging, setDragging] = useState(false);

  const [renderedImage, setRenderedImage] = useState();

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const files = Array.from(e.dataTransfer.files);
    onSettingFileHandler(files[0]);
  };

  const onInputChangeHandler = (e) => {
    if (e.target.files) {
      onSettingFileHandler(e.target.files[0]);
    }
  };

  const onSettingFileHandler = (Image) => {
    Image.uploadedAt = new Date();

    setData(Image);
    // console.log(Image);
    const reader = new FileReader();

    reader.onloadend = () => {
      setRenderedImage(reader.result);
    };

    reader.readAsDataURL(Image);
  };

  const generateFile = () => {
    if (!data) return;

    if (isImage === true && isArray === false) {
      return (
        <div className="flex flex-col items-center justify-center gap-1 text-sm text-gray-600 font-medium">
          <button
            onClick={() => {
              setData();
              setRenderedImage();
            }}
            className="self-start flex items-center justify-start gap-1 hover:text-red-600 duration-200 text-base font-semibold"
          >
            <CloseSvg />
            حذف فایل
          </button>
          <img
            src={renderedImage}
            alt="banner"
            className="w-full sm:w-1/2 rounded-lg"
          />
          <span>نام فایل : {data.name}</span>
          <span>حجم فایل : {formatFileSize(data.size)}</span>
        </div>
      );
    } else if (isArray === false && isImage === false) {
      return (
        <div className="flex items-center justify-center gap-5 text-sm text-gray-600 font-medium">
          <button
            onClick={() => {
              setData();
              setRenderedImage();
            }}
            className="flex items-center justify-start gap-1 hover:text-red-600 duration-200 text-base font-semibold"
          >
            <CloseSvg />
            حذف فایل
          </button>
          <div className="grow bg-white p-3 rounded-lg flex items-center justify-start gap-3">
            <span>نام فایل : {data.name}</span>
            <span>حجم فایل : {formatFileSize(data.size)}</span>
            <a
              className="hover:text-blue-800 duration-200"
              href={URL.createObjectURL(data)}
              target="_blank"
              rel="noreferrer"
            >
              مشاهده فایل
            </a>
          </div>
        </div>
      );
    } else {
      return data.map((singleFile, index) => (
        <div
          key={index}
          className="flex items-center justify-center gap-5 text-sm text-gray-600 font-medium"
        >
          <button
            onClick={() => {
              onDeleteSingleFile(index);
            }}
            className="flex items-center justify-start gap-1 hover:text-red-600 duration-200 text-base font-semibold"
          >
            <CloseSvg />
            حذف فایل
          </button>
          <div className="grow bg-white p-3 rounded-lg flex items-center justify-start gap-3">
            <span>نام فایل : {singleFile.name}</span>
            <span>حجم فایل : {formatFileSize(singleFile.size)}</span>
            <a
              className="hover:text-blue-800 duration-200"
              href={URL.createObjectURL(singleFile)}
              target="_blank"
              rel="noreferrer"
            >
              مشاهده فایل
            </a>
          </div>
        </div>
      ));
    }
  };

  const generateGetFileHandler = () => {
    if (data && !isArray) return;

    return (
      <div
        onClick={() => fileInputContainer.current.click()}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        className={`${
          dragging ? "border-blue-500" : ""
        } duration-200 w-full flex flex-col items-center justify-center gap-5 border-2 border-dashed rounded-md p-5 text-sm md:text-base`}
      >
        <span className="text-gray-400">
          برای اضافه کردن فایل مورد نظر را بکشید
        </span>
        <button
          onClick={() => fileInputContainer.current.click()}
          className="text-white px-4 py-2 rounded-md bg-blue-700 border-2 border-blue-700 hover:bg-transparent hover:text-blue-700 duration-200"
        >
          آپلود فایل
        </button>
      </div>
    );
  };

  return (
    <div className="my-5 space-y-5">
      {" "}
      <input
        onChange={onInputChangeHandler}
        ref={fileInputContainer}
        type={"file"}
        className="hidden"
        accept={acceptableType}
      />
      <label className="text-lg font-semibold">{title}</label>
      {generateGetFileHandler()}
      {generateFile()}
    </div>
  );
};

export default CharityDropFile;
