import { useEffect, useState } from "react";

//react router dom
import { Link, useParams } from "react-router-dom";

//service
import { GetSingleEmployee } from "../../../../services/employee";

//svg
import { ReactComponent as Back } from "../../../../assets/svg/backward.svg";
import { toast } from "react-toastify";

const SingleEmployee = () => {
  const { id } = useParams();

  //data
  const [singleEmployee, setSingleEmployee] = useState({});

  useEffect(() => {
    httpGetSingleEmployee();
  }, []);

  const httpGetSingleEmployee = async () => {
    try {
      const response = await GetSingleEmployee({ id });

      if (response.status === 200) {
        setSingleEmployee({
          ...response.data.data,
        });
      } else {
        toast.error("دریافت مسول تکی ناموفق بود");
      }
    } catch (error) {
      console.log("error in getting single employee : ", error);
    }
  };

  return (
    <div className="flex flex-col mb-10">
      <Link className="self-end" to={"/employees"}>
        <button className="flex items-center  gap-2 bg-[#435bf1] text-white -mt-16 w-fit py-3 px-4 rounded-md">
          <Back />
          بازگشت
        </button>
      </Link>
      <div className="flex flex-col gap-8 bg-white p-8 rounded-md">
        <center>
          <img
            src={singleEmployee.image}
            alt="banner"
            className="bg-slate-400  h-[350px] w-[350px] rounded-sm object-fill"
          />
        </center>
        <div className="flex flex-col items-start gap-7">
          <div className="flex flex-col items-start gap-1">
            <span className=""> نام مسول</span>
            <p className="text-lg font-medium">{singleEmployee.first_name}</p>
          </div>
          <div className="flex items-end justify-between gap-2 flex-wrap w-full">
            <div className="flex flex-col items-center gap-1">
              <span>نام خانوادگی</span>
              <span className="text-xl font-medium">{singleEmployee.last_name}</span>
            </div>
            <div className="flex flex-col items-center gap-1">
              <span>شماره تلفن</span>
              <span className="text-xl font-medium">{singleEmployee.phone_number}</span>
            </div>
            <div className="flex flex-col items-center gap-1">
              <span>کد ملی</span>
              <span className="text-xl font-medium">{singleEmployee.national_code}</span>
            </div>
            <div className="flex flex-col items-center gap-1">
              <span>مسولیت</span>
              <span className="text-xl font-medium">{singleEmployee.employee_category}</span>
            </div>
          </div>
          <div className="flex flex-col items-start gap-1">
            <span className=""> توضیحات</span>
            <p
            className="leading-10 text-[#353535] font-medium"
            dangerouslySetInnerHTML={{ __html: singleEmployee.caption }}
          ></p>
          </div>

          <div className="flex items-end justify-between gap-2 flex-wrap w-full">
            <div className="flex flex-col items-center gap-1">
              <span>ایمیل</span>
              <span className="text-xl font-medium">{singleEmployee.email}</span>
            </div>
            <div className="flex flex-col items-center gap-1">
              <span>تاریخ تولد</span>
              <span className="text-xl font-medium">
                {singleEmployee.birthday}
              </span>
            </div>
          </div>
          {/* <div>
            <span>تگ ها</span>
            <div className="flex items-center justify-start gap-1 text-lg font-medium">
              {singleCourse.tags !== undefined &&
                singleCourse.tags
                  .split(",")
                  .map((singleTag, index) => (
                    <span key={index}>{singleTag} ,</span>
                  ))}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SingleEmployee;
