import { useState } from "react";
//SVG
import { ReactComponent as Tik } from "../../../../assets/svg/tik.svg";

const PhoneInformation = () => {
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const data = {
        name: "علی محجوب",
        nationalnumber: 12345678,
        birthdate: " 1 / 1 / 1380",
        gender: "مرد",
        phone: "09181233254",
        email: "ali@gmail.com",
        ostan: "همدان",
        city: "تویسرکان",
        adres: "",
    };
    return ( <
        div className = "flex flex-col w-full justify-center items-center" >
        <
        div className = "w-11/12 flex flex-col gap-8  bg-white p-8 rounded-md" >
        <
        span className = "font-bold " > اطلاعات تماس < /span> <
        div className = "grid grid-cols-2 gap-10" >
        <
        div className = "flex gap-5 border-2 px-4 py-3 rounded-md border-[#F3F3F3]" >
        <
        span className = "text-[#AFAFAF]" > شماره موبایل < /span> <
        span className = "font-bold" > { data.phone } < /span> < /
        div > <
        div className = "flex justify-between h-fit gap-5 border-2 px-4 py-3 rounded-md border-[#F3F3F3] " >
        <
        span className = "text-[#AFAFAF]" > پست الکترونیک < /span> <
        span className = "font-bold" > { data.email } < /span> < /
        div > <
        div className = "flex gap-5 border-2 px-4 py-3 rounded-md border-[#F3F3F3] " >
        <
        span className = "text-[#AFAFAF]" > استان < /span> <
        span className = "font-bold" > { data.ostan } < /span> < /
        div > <
        div className = "flex gap-5 border-2 px-4 py-3 rounded-md border-[#F3F3F3] " >
        <
        span className = "text-[#AFAFAF]" > شهر < /span> <
        span className = "font-bold" > { data.city } < /span> < /
        div > <
        div className = "col-span-2" >
        <
        div className = "flex gap-5 border-2 px-4 py-3 rounded-md border-[#F3F3F3] " >
        <
        span className = "text-[#AFAFAF]" > آدرس کامل < /span> <
        span className = "font-bold" > آدرس رو اینجا بنویس جانم! < /span> < /
        div > <
        span className = "flex mt-4 text-[#AFAFAF]" >
        <
        Tik className = "w-10 mt-2" / >
        آدرس محل سکونت می بایست شامل کوچه - خیابون - پلاک و...باشد. <
        /span> < /
        div > <
        /div> <
        button onClick = {
            () => setIsOpenEdit(true)
        }
        className = "bg-[#435bf1] w-fit self-end py-3 px-4 rounded-md" >
        ویرایش اطلاعات <
        /button> < /
        div > <
        div > {
            isOpenEdit ? ( <
                div className = "w-screen h-screen fixed top-0 left-0 flex flex-col justify-center items-center gap-8  bg-[#1b1a1a93]" >
                <
                div className = "flex flex-col w-11/12 gap-8  bg-white p-8 rounded-md" >
                <
                span className = "font-bold " > ویرایش اطلاعات تماس < /span> <
                div className = "grid grid-cols-2 gap-10" >
                <
                div className = "flex flex-col gap-2 px-4" >
                <
                span className = "text-[#AFAFAF]" > شماره موبایل < /span> <
                input className = "font-bold py-2 border-2 rounded-md"
                value = { data.phone }
                /> < /
                div > <
                div className = "flex flex-col gap-2 px-4" >
                <
                span className = "text-[#AFAFAF]" > پست الکترونیک < /span> <
                input className = "font-bold py-2 border-2 rounded-md"
                value = { data.email }
                /> < /
                div > <
                div className = "flex flex-col gap-2 px-4" >
                <
                span className = "text-[#AFAFAF]" > استان < /span> <
                input className = "font-bold py-2 border-2 rounded-md"
                value = { data.ostan }
                /> < /
                div > <
                div className = "flex flex-col gap-2 px-4" >
                <
                span className = "text-[#AFAFAF]" > شهر < /span> <
                input className = "font-bold py-2 border-2 rounded-md"
                value = { data.city }
                /> < /
                div > <
                div className = "col-span-2" >
                <
                div className = "flex flex-col gap-2 px-4" >
                <
                span className = "text-[#AFAFAF]" > آدرس کامل < /span> <
                input className = "font-bold py-2 border-2 rounded-md"
                value = { data.adres }
                /> < /
                div > <
                span className = "flex mt-4 text-[#AFAFAF]" >
                <
                Tik className = "w-10 mt-2" / >
                آدرس محل سکونت می بایست شامل کوچه - خیابون - پلاک و...باشد. <
                /span> < /
                div > <
                /div> <
                div className = "flex justify-between" >
                <
                button onClick = {
                    () => setIsOpenEdit(false)
                }
                className = "border-2 border-[#435bf1] text-[#435bf1] w-fit self-end py-3 px-4 rounded-md" >
                بستن <
                /button> <
                button className = "bg-[#435bf1] text-white w-fit self-end py-3 px-4 rounded-md" >
                ذخیره تغییرات <
                /button> < /
                div > <
                /div> < /
                div >
            ) : ( <
                >
                <
                />
            )
        } <
        /div> < /
        div >
    );
};

export default PhoneInformation;