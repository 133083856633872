import React from 'react';

//SVG
import { ReactComponent as Logo } from './../../../../assets/svg/charity-logo.svg';
import { ReactComponent as DashboardSVG } from './../../../../assets/svg/dashboard.svg';
import { ReactComponent as CommitesSVG } from './../../../../assets/svg/commites.svg';
import { ReactComponent as EducationalCoursesSVG } from './../../../../assets/svg/educationalCourses.svg';
import { ReactComponent as UsersSVG } from './../../../../assets/svg/user.svg';
import { ReactComponent as MessagePanelSVG } from './../../../../assets/svg/messagePanel.svg';
import { ReactComponent as NewsSVG } from './../../../../assets/svg/news.svg';
import { ReactComponent as SettingsSVG } from './../../../../assets/svg/setting.svg';
import { ReactComponent as ImageSVG } from './../../../../assets/svg/Image.svg';
import MapMenu from './mapMenu';
import UserProfile from '../../userProfile';

const mainMenu = [
  {
    title: 'داشبورد من',
    route: '/dashboard',
    Icon: DashboardSVG,
  },
  {
    title: 'کمیته ها',
    route: '/commites',
    Icon: CommitesSVG,
  },
  {
    title: 'دوره های آموزشی',
    route: '/educationalCourses',
    Icon: EducationalCoursesSVG,
  },
  {
    title: 'اعضای گروه جهادی',
    route: '/users',
    Icon: UsersSVG,
  },
  {
    title: 'مسولین گروه جهادی',
    route: '/employees',
    Icon: UsersSVG,
  },
];

const quickAccess = [
  {
    title: 'پنل پیامکی',
    route: '/messagePanel',
    Icon: MessagePanelSVG,
  },
  {
    title: 'اخبار گروه جهادی',
    route: '/news',
    Icon: NewsSVG,
  },
  {
    title: 'گزارش عملکرد گروه جهادی',
    route: '/action',
    Icon: NewsSVG,
  },
  {
    title: 'متن عنوان',
    route: '/title/text',
    Icon: NewsSVG,
  },
  {
    title: 'عکس عنوان',
    route: '/title/images',
    Icon: ImageSVG,
  },

];

const accountManagement = [
  {
    title: 'تنظیمات',
    route: '/settings',
    Icon: SettingsSVG,
  },
];

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  //   const [isShowLogoutModal, setIsShowLogoutModal] = useState(false);

  return (
    <>
      <aside
        className={`min-h-screen overflow-auto transition-all duration-300 z-[1000000] lg:z-0 xl:col-span-2 lg:col-span-3 col-span-5 lg:relative fixed lg:right-0 top-0 -right-full flex flex-col items-center bg-white ${
          sidebarOpen && '!right-0 w-[276px]'
        }`}
      >
        <div className="flex justify-between relative items-center pt-3">
          <div className="flex items-center justify-center gap-2">
            <Logo className="w-28 h-28 rounded-full" />
            <span className="text-lg text-center font-semibold text-[#3E4095] w-1/2 pl-5">موسسه خیریه شهید حججی</span>
          </div>
          <button
            type="button"
            onClick={() => setSidebarOpen(false)}
            className="lg:hidden text-black absolute top-8 bottom-full mb-1 left-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="sm:overflow-visible overflow-auto sm:min-h-screen w-full mt-10 sm:mt-14 pb-20">
          <ul className="flex flex-col gap-y-5">
            <MapMenu header="منوی اصلی" list={mainMenu} setSidebarOpen={setSidebarOpen} />
            <MapMenu header="دسترسی سریع" list={quickAccess} setSidebarOpen={setSidebarOpen} />
            <div className="my-2"></div>
            <MapMenu header="مدیریت حساب" list={accountManagement} setSidebarOpen={setSidebarOpen} />
            <UserProfile />
          </ul>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
