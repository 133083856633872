import { useState } from "react";
import { Link } from "react-router-dom";

//component
import DeleteSingleCommitteeModal from "../deleteSingleCommitteeModal";

const SingleCommiteCard = ({ singleCommite, index, lastIndex }) => {
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  return (
    <>
      <div
        className={`grid grid-cols-12 gap-5 w-full ${
          index !== lastIndex ? "border-b" : ""
        } text-center justify-items-center bg-white py-3 items-center  my-2 font-medium text-[#74787C]`}
      >
        <span className="col-span-1 text-center">{index + 1}</span>
        <img
          className="col-span-2 bg-slate-500 w-3/4 h-14 rounded-md object-cover"
          src={singleCommite.image}
          alt="banner"
        />
        <span className="col-span-2 truncate w-full">{singleCommite.name}</span>
        <span className="col-span-4 truncate w-full">
          {singleCommite.short}
        </span>
        <div className="col-span-3 flex items-center gap-2 justify-center flex-wrap">
          <button
            onClick={() => setIsShowDeleteModal(true)}
            className="duration-200 px-4 py-2 hover:border-[#ED2E2E] hover:text-[#ED2E2E] text-[#717171] text-sm border-2 rounded-md "
          >
            حذف
          </button>
          <Link
            to={`/commites/newcommite?status=edit&id=${singleCommite.id}`}
            className="duration-200 px-4 py-2 hover:border-[#F4B740] hover:text-[#F4B740] text-[#717171] text-sm border-2 rounded-md"
          >
            ویرایش
          </Link>
          <Link to={`/commites/detail/${singleCommite.id}`}>
            <button className="duration-200 px-4 py-2 hover:border-[#00BA88] hover:text-[#00BA88] text-[#717171] text-sm border-2 rounded-md">
              جزئیات
            </button>
          </Link>
        </div>
      </div>
      <div>
        {isShowDeleteModal && (
          <DeleteSingleCommitteeModal
            isShowModal={isShowDeleteModal}
            id={singleCommite.id}
            name={singleCommite.name}
            closeModalHandler={() => setIsShowDeleteModal(false)}
          />
        )}
      </div>
    </>
  );
};

export default SingleCommiteCard;
