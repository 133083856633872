import React, { useEffect, useState } from "react";

//react router dom
import { Link, useNavigate } from "react-router-dom";
import useCustomSearchParams from "../../../../hooks/useCustomSearchParams";
//service


//component
// import CharityTextEditor from "../../../../components/common/charityTextEditor";
import CharityDropFile from "../../../../components/common/dropFile";

//SVG
import { ReactComponent as Back } from "../../../../assets/svg/backward.svg";
import { toast } from "react-toastify";
import { CreateNewImages, EditImages, GetSingleImages } from "../../../../services/title";
// import { check } from "prettier";

const AddNewImage = () => {
  //navigate
  const navigate = useNavigate();

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [searchParams] = useCustomSearchParams();

  //data
  const [dataSchema, setDataSchema] = useState({
    statusImage: "",
  });

  const [image, setImage] = useState();

  useEffect(() => {
    //check if we are in edit mode
    if (searchParams.status === "edit") {
      httpGetSingleImage();
    }
  }, []);

  const onSetDataSchemaHandler = (target, value) => {
    setDataSchema((prevState) => ({
      ...prevState,
      [target]: value,
    }));
  };

  const httpGetSingleImage = async () => {
    try {
      const response = await GetSingleImages({ id: searchParams.id });

      if (response.status === 200) {
        setDataSchema({
          ...response.data.data,
        });
      }
    } catch (error) {
      console.log("error in get signle news : ", error);
    }
  };

  const httpCreateNewImages = async () => {
    try {
      setButtonDisabled(true)
      const response = await CreateNewImages({
        status: dataSchema.statusImage,
        image,
      });

      if (response.status === 200) {
        setButtonDisabled(false)
        navigate("/title/images");
      } else {
        setButtonDisabled(false)
        toast.error("اضافه کردن تصویر ناموفق بود");
      }
    } catch (error) {
      console.log("error in create new news : ", error);
    }
  };

  const httpEditImage = async () => {
    setButtonDisabled(true)
    try {
      const response = await EditImages({
        id: searchParams.id,
        status: dataSchema.statusImage,
      });

      if (response.status === 200) {
        setButtonDisabled(false)
        navigate("/title/images");
      } else {
        setButtonDisabled(false)
        toast.error("ویرایش کردن تصویر ناموفق بود");
      }
    } catch (error) {
      console.log("error in edit new image : ", error);
    }
  };

  // const httpDeleteBanner = async () => {
  //   try {
  //     const response = await DeleteNewsBanner({ id: searchParams.id });

  //     if (response.status === 200) {
  //       toast.success("بنر تصویر با موفقیت حذف شد");
  //       navigate(0);
  //     } else {
  //       toast.error("حذف بنر موفقیت آمیز نبود");
  //     }
  //   } catch (error) {
  //     console.log("error in delete Banner : ", error);
  //   }
  // };

  return (
    <div className="flex flex-col">
      <Link className="self-end" to={"/title/images"}>
        <button className="flex items-center  gap-2 bg-[#435bf1] text-white -mt-16 w-fit py-3 px-4 rounded-md">
          <Back />
          بازگشت
        </button>
      </Link>
      <div className="flex flex-col gap-8 bg-white p-8 rounded-md">
        <span className="font-bold">ایجاد تصویر جدید</span>

        <div className="flex flex-col relative">
          <span className="absolute bg-white -top-3 right-2 px-3">
            وضعیت تصویر
          </span>
          <input
            checked={dataSchema.statusImage ? true : false}
            onChange={(e) => onSetDataSchemaHandler("statusImage", e.target.checked)}
            type="checkbox"
            className="border-2 border-[#CBCBCB] p-2 rounded-md "
          />
        </div>

        {/* <div className="flex flex-col relative">
          <CharityTextEditor
            title={"description"}
            value={dataSchema.description}
            getValue={onSetDataSchemaHandler}
          />
        </div> */}

        {dataSchema.image ? (
          <div>
            {/* <button
              onClick={httpDeleteBanner}
              className="px-7 py-2 text-white font-medium bg-red-700 rounded-md mb-5"
            >
              حذف عکس
            </button> */}
            <img
              className="h-[300px] w-full object-contain"
              src={dataSchema.image}
              alt="banner"
            />
          </div>
        ) : (
          <CharityDropFile
            data={image}
            setData={(e) => setImage(e)}
            acceptableType={"image/png, image/gif, image/jpeg"}
            isArray={false}
            isImage={true}
          />
        )}
        {searchParams.status === "edit" ? (
          <button
            onClick={httpEditImage}
            disabled={buttonDisabled}
            className="px-8 py-2 rounded-md bg-blue-700 border-2 border-blue-700 text-white hover:text-blue-700 hover:bg-white duration-200 w-fit mr-auto"
          >
            ویرایش کردن
          </button>
        ) : (
          <button
            onClick={httpCreateNewImages}
            disabled={buttonDisabled}
            className="px-8 py-2 rounded-md bg-blue-700 border-2 border-blue-700 text-white hover:text-blue-700 hover:bg-white duration-200 w-fit mr-auto"
          >
            اضافه کردن
          </button>
        )}
      </div>
    </div>
  );
};

export default AddNewImage;
