import useFetch from '../hooks/useFetch';
const endPoint = 'users';

export async function GetAllUser({ filter }) {
  const apiCall = await useFetch().get(`${endPoint}?${filter}`);
  return apiCall;
}

export async function RemoveSingleUser({ id }) {
  const apiCall = await useFetch().delete(`${endPoint}/${id}`);
  return apiCall;
}

export async function GetUserTypesList() {
  const apiCall = await useFetch().get(`${endPoint}/create`);
  return apiCall;
}

export async function GetAllCommittesList() {
  const apiCall = await useFetch().get(`user/committees`);
  return apiCall;
}

export async function CreateNewUser({
  phone_number,
  first_name,
  last_name,
  email,
  national_code,
  birthday,
  user_type,
  committees,
  alms,
  city,
  degree,
}) {
  let newuserData = {
    phone_number,
    first_name,
    last_name,
    email,
    national_code,
    birthday,
    city_id: city,
    degree,
    user_type: user_type.join(','),
  };

  if (user_type.includes(1)) {
    newuserData.alms = alms;
  }

  if (user_type.includes(2)) {
    newuserData.committees = committees;
  }

  const apiCall = await useFetch().post(`${endPoint}`, {
    ...newuserData,
  });
  return apiCall;
}

export async function EditUser({
  id,
  phone_number,
  first_name,
  last_name,
  email,
  national_code,
  birthday,
  user_type,
  committees,
  alms,
  city,
  degree,
}) {
  let newuserData = {
    phone_number,
    first_name,
    last_name,
    email,
    national_code,
    birthday,
    city_id: city,
    degree,
    user_type: user_type.join(','),
  };

  if (user_type.includes(1)) {
    newuserData.alms = alms;
  }

  if (user_type.includes(2)) {
    newuserData.committees = committees;
  }

  const apiCall = await useFetch().put(`${endPoint}/${id}`, { ...newuserData });
  return apiCall;
}

export async function GetUserEdit({ id }) {
  const apiCall = await useFetch().get(`${endPoint}/${id}/edit`);
  return apiCall;
}
