import React, { useEffect, useState } from 'react';

//react router dom
import { Link, useNavigate } from 'react-router-dom';
import useCustomSearchParams from '../../../../hooks/useCustomSearchParams';

import { ReactComponent as Pdf } from '../../../../assets/svg/pdf.svg';

//service
import {
  CreateNewEmployee,
  GetSingleEmployee,
  EditEmployee,
  DeleteEmployeeBanner,
  EditImage,
} from '../../../../services/employee';

//component
import CharityTextEditor from '../../../../components/common/charityTextEditor';
import CharityDropFile from '../../../../components/common/dropFile';

//SVG
import { ReactComponent as Back } from '../../../../assets/svg/backward.svg';
import { toast } from 'react-toastify';

const AddNewEmployee = () => {
  //navigate
  const navigate = useNavigate();

  const [searchParams] = useCustomSearchParams();

  //data
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [dataSchema, setDataSchema] = useState({
    first_name: '',
    last_name: '',
    email: '',
    national_code: '',
    phone_number: '',
    employee_category: '',
    caption: '',
    birthday: '',
  });

  const [image, setImage] = useState();

  useEffect(() => {
    //check if we are in edit mode
    if (searchParams.status === 'edit') {
      httpGetSingleEmployee();
    }
  }, []);

  const onSetDataSchemaHandler = (target, value) => {
    setDataSchema((prevState) => ({
      ...prevState,
      [target]: value,
    }));
  };

  const httpGetSingleEmployee = async () => {
    try {
      const response = await GetSingleEmployee({ id: searchParams.id });

      if (response.status === 200) {
        setDataSchema({
          ...response.data.data,
        });
      }
    } catch (error) {
      console.log('error in get signle employee : ', error);
    }
  };

  const httpCreateNewEmployee = async () => {
    try {
      setButtonDisabled(true);
      const response = await CreateNewEmployee({
        ...dataSchema,
        image,
      });

      if (response.status === 200) {
        setButtonDisabled(false);
        navigate('/employees');
      } else {
        setButtonDisabled(false);
        toast.error('اضافه کردن مسول ناموفق بود');
      }
    } catch (error) {
      console.log('error in create new employee : ', error);
    }
  };

  const httpEditEmployee = async () => {
    console.log(image);
    try {
      setButtonDisabled(true);

      const response = await EditEmployee({
        id: searchParams.id,
        ...dataSchema,
      });

      if (image !== undefined) {
        await EditImage({
          id: searchParams.id,
          image,
        });
      }
      if (response.status === 200) {
        setButtonDisabled(false);
        navigate('/employees');
      } else {
        setButtonDisabled(false);
        toast.error('ویرایش کردن مسول ناموفق بود');
      }
    } catch (error) {
      console.log('error in edit new employee : ', error);
    }
  };

  const httpDeleteBanner = async () => {
    try {
      setButtonDisabled(true);
      const response = await DeleteEmployeeBanner({ id: searchParams.id });

      if (response.status === 200) {
        setButtonDisabled(false);
        toast.success(' عکس مسول با موفقیت حذف شد');
        navigate(0);
      } else {
        setButtonDisabled(false);
        toast.error('حذف مسول موفقیت آمیز نبود');
      }
    } catch (error) {
      console.log('error in delete Banner : ', error);
    }
  };

  return (
    <div className="flex flex-col">
      <Link className="self-end" to={'/employees'}>
        <button className="flex items-center  gap-2 bg-[#435bf1] text-white -mt-16 w-fit py-3 px-4 rounded-md">
          <Back />
          بازگشت
        </button>
      </Link>
      <div className="flex flex-col gap-8 bg-white p-8 rounded-md">
        <span className="font-bold">ایجاد مسول جدید</span>
        <div className="flex flex-col relative">
          <span className="absolute bg-white -top-3 right-2 px-3">نام</span>
          <input
            value={dataSchema.first_name}
            onChange={(e) => onSetDataSchemaHandler('first_name', e.target.value)}
            placeholder="نام مسول جدید رو اینجا بنویس!"
            className="border-2 border-[#CBCBCB] p-2 rounded-md "
            required={true}
          />
        </div>
        <div className="flex flex-col relative">
          <span className="absolute bg-white -top-3 right-2 px-3">نام خانوادگی</span>
          <input
            value={dataSchema.last_name}
            onChange={(e) => onSetDataSchemaHandler('last_name', e.target.value)}
            placeholder="نام خانوادگی مسول جدید رو اینجا بنویس!"
            className="border-2 border-[#CBCBCB] p-2 rounded-md "
            required={true}
          />
        </div>

        <div className="flex flex-col relative">
          <span className="absolute bg-white -top-3 right-2 px-3">ایمیل</span>
          <input
            value={dataSchema.email}
            onChange={(e) => onSetDataSchemaHandler('email', e.target.value)}
            placeholder=" ایمیل مسول جدید رو اینجا بنویس!"
            className="border-2 border-[#CBCBCB] p-2 rounded-md "
            required={true}
          />
        </div>

        <div className="flex flex-col relative">
          <span className="absolute bg-white -top-3 right-2 px-3">کد ملی</span>
          <input
            value={dataSchema.national_code}
            onChange={(e) => onSetDataSchemaHandler('national_code', e.target.value)}
            placeholder=" کد ملی مسول جدید رو اینجا بنویس!"
            className="border-2 border-[#CBCBCB] p-2 rounded-md "
            required={true}
          />
        </div>

        <div className="flex flex-col relative">
          <span className="absolute bg-white -top-3 right-2 px-3"> مسولیت</span>
          <input
            value={dataSchema.employee_category}
            onChange={(e) => onSetDataSchemaHandler('employee_category', e.target.value)}
            placeholder=" رمز عبور مسول جدید رو اینجا بنویس!"
            className="border-2 border-[#CBCBCB] p-2 rounded-md "
            required={true}
          />
        </div>

        <div className="flex flex-col relative">
          <span className="absolute bg-white -top-3 right-2 px-3">تاریخ تولد</span>
          <input
            value={dataSchema.birthday}
            onChange={(e) => onSetDataSchemaHandler('birthday', e.target.value)}
            placeholder=" تاریخ تولد مسول جدید رو اینجا بنویس!"
            className="border-2 border-[#CBCBCB] p-2 rounded-md "
            required={true}
          />
        </div>

        <div className="flex flex-col relative">
          <span className="absolute bg-white -top-3 right-2 px-3">شماره تلفن</span>
          <input
            value={dataSchema.phone_number}
            onChange={(e) => onSetDataSchemaHandler('phone_number', e.target.value)}
            placeholder=" شماره تلفن مسول جدید رو اینجا بنویس!"
            className="border-2 border-[#CBCBCB] p-2 rounded-md "
            required={true}
          />
        </div>

        <div className="flex flex-col relative">
          <span className="bg-white mb-2 px-3">توضیحات مسول</span>
          <CharityTextEditor title={'caption'} value={dataSchema.caption} getValue={onSetDataSchemaHandler} />
        </div>

        {dataSchema.image ? (
          <div>
            <span className="">تصویری برای مسول</span>
            <button
              onClick={httpDeleteBanner}
              disabled={buttonDisabled}
              className="px-7 py-2 text-white font-medium bg-red-700 rounded-md mb-5"
            >
              حذف عکس
            </button>
            <img className="h-[300px] w-full object-contain" src={dataSchema.image} alt="banner" />
          </div>
        ) : (
          <div>
            <span className="">تصویری برای مسول</span>
            <CharityDropFile
              data={image}
              setData={(e) => setImage(e)}
              acceptableType={'image/png, image/gif, image/jpeg'}
              isArray={false}
              isImage={true}
            />
          </div>
        )}

        {searchParams.status === 'edit' ? (
          <button
            onClick={httpEditEmployee}
            disabled={buttonDisabled}
            className="px-8 py-2 rounded-md bg-blue-700 border-2 border-blue-700 text-white hover:text-blue-700 hover:bg-white duration-200 w-fit mr-auto"
          >
            ویرایش کردن
          </button>
        ) : (
          <button
            onClick={httpCreateNewEmployee}
            disabled={buttonDisabled}
            className="px-8 py-2 rounded-md bg-blue-700 border-2 border-blue-700 text-white hover:text-blue-700 hover:bg-white duration-200 w-fit mr-auto"
          >
            اضافه کردن
          </button>
        )}
      </div>
    </div>
  );
};

export default AddNewEmployee;
