import useFetch from "../hooks/useFetch";
const endPoint = "committees";

export async function GetAllCommitte({ filter }) {
  const apiCall = await useFetch().get(`${endPoint}?${filter}`);
  return apiCall;
}

export async function DeleteSingleCommittee({ id }) {
  const apiCall = await useFetch().delete(`${endPoint}/${id}`);

  return apiCall;
}

export async function GetSingleCommittee({ id }) {
  const apiCall = await useFetch().get(`${endPoint}/${id}`);

  return apiCall;
}

export async function CreateNewCommittee({
  name,
  caption,
  image,
}) {
  const formData = new FormData();

  formData.append("title", name);
  formData.append("caption", caption);
  formData.append("image", image);

  const apiCall = await useFetch().post(`${endPoint}`, formData);

  return apiCall;
}

export async function EditCommittee({
  id,
  name,
  caption,
}) {
  const data = {
    title: name,
    caption: caption,
  };

  const apiCall = await useFetch().put(`${endPoint}/${id}`, data);

  return apiCall;
}

export async function EditImage({ id, image }) {
  const formData = new FormData();

  formData.append("image", image);

  const apiCall = await useFetch().post(`${endPoint}/image/${id}`, formData);

  return apiCall;
}

export async function DeleteCommitteeBanner({ id }) {
  const apiCall = await useFetch().delete(`${endPoint}/image/${id}`);

  return apiCall;
}
