import React from "react";

const Card = ({ title, number, svg }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-5 p-3 rounded-lg bg-white w-full font-medium">
      <div className="flex justify-between w-full">
        <span>{title}</span>
        <span>{number} نفر</span>
      </div>
      {svg}
    </div>
  );
};

export default Card;
