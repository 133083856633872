import React, { useEffect, useState } from 'react';

//react router dom
import { Link, useNavigate } from 'react-router-dom';
import useCustomSearchParams from '../../../../hooks/useCustomSearchParams';
//service
import { GetUserTypesList, GetAllCommittesList, CreateNewUser, EditUser, GetUserEdit } from '../../../../services/user';

import CharityDatePicker from '../../../../components/common/datePicker';

//SVG
import { ReactComponent as Back } from '../../../../assets/svg/backward.svg';
import { toast } from 'react-toastify';
import AddLocationsList from '../../../../components/page/user/locations';

const AddAndEditUser = () => {
  const [buttonDisabel, setButtonDisabel] = useState(false);
  //navigate
  const navigate = useNavigate();

  const [searchParams] = useCustomSearchParams();

  //data
  const [dataSchema, setDataSchema] = useState({
    first_name: '',
    last_name: '',
    national_code: 0,
    phone_number: '',
    birthday: new Date(),
    user_type: [],
    degree: 'لیسانس'
  });
  const [usersType, setUsersType] = useState([]);
  const [committes, setCommittes] = useState([]);

  useEffect(() => {
    //check if we are in edit mode
    if (searchParams.status === 'edit') {
      httpGetSingleUser();
    }

    httpGetAllUsersType();
    httpGetAllCommiteList();
  }, []);

  const onSetDataSchemaHandler = (target, value) => {
    setDataSchema((prevState) => ({
      ...prevState,
      [target]: value,
    }));
  };

  const onSetCheckboxHandler = (checked, targetType) => {
    setDataSchema((prevState) => {
      if (checked) {
        return {
          ...prevState,
          user_type: [...prevState.user_type, targetType],
        };
      } else {
        return {
          ...prevState,
          user_type: [...prevState.user_type.filter((singleuserType) => targetType !== singleuserType)],
        };
      }
    });
  };

  const httpGetAllUsersType = async () => {
    try {
      const response = await GetUserTypesList();

      if (response.status === 200) {
        setUsersType([...response.data]);
      }
    } catch (error) {
      console.log('error in get all users type : ', error);
    }
  };

  const httpGetAllCommiteList = async () => {
    try {
      const response = await GetAllCommittesList();
      if (response.status === 200) {
        setCommittes([...response.data.data]);
      }
    } catch (error) {
      console.log('error in get all commite : ', error);
    }
  };

  const httpGetSingleUser = async () => {
    try {
      const response = await GetUserEdit({ id: searchParams.id });

      if (response.status === 200) {
        setDataSchema({
          ...response.data.data.user,
        });
      }
    } catch (error) {
      console.log('error in get signle user : ', error);
    }
  };

  const httpCreateNewUser = async () => {
    try {
      setButtonDisabel(true);

      const response = await CreateNewUser({
        ...dataSchema,
      });

      if (response.status === 200) {
        setButtonDisabel(false);
        navigate('/users');
      } else {
        setButtonDisabel(false);
        toast.error('اضافه کردن کاربر ناموفق بود');
      }
    } catch (error) {
      console.log('error in create new user : ', error);
    }
  };

  const httpEditUser = async () => {
    try {
      setButtonDisabel(true);

      const response = await EditUser({
        id: searchParams.id,
        ...dataSchema,
      });

      if (response.status === 200) {
        setButtonDisabel(false);
        navigate('/users');
      } else {
        setButtonDisabel(false);
        toast.error('ویرایش کردن دوره ناموفق بود');
      }
    } catch (error) {
      console.log('error in edit new committee : ', error);
    }
  };

  console.log('dataSchema : ', dataSchema);

  return (
    <div className="flex flex-col">
      <Link className="self-end" to={'/users'}>
        <button className="flex items-center  gap-2 bg-[#435bf1] text-white -mt-16 w-fit py-3 px-4 rounded-md">
          <Back />
          بازگشت
        </button>
      </Link>
      <div className="flex flex-col gap-8 bg-white p-8 rounded-md">
        <span className="font-bold">ایجاد عضو جدید</span>
        <div className="flex flex-col relative">
          <span className="absolute bg-white -top-3 right-2 px-3">نام</span>
          <input
            value={dataSchema.first_name}
            onChange={(e) => onSetDataSchemaHandler('first_name', e.target.value)}
            placeholder="نام کاربر را وارد کنید"
            className="border-2 border-[#CBCBCB] p-2 rounded-md "
          />
        </div>

        <div className="flex flex-col relative">
          <span className="absolute bg-white -top-3 right-2 px-3">نام خانوادگی</span>
          <input
            value={dataSchema.last_name}
            onChange={(e) => onSetDataSchemaHandler('last_name', e.target.value)}
            placeholder="نام خانوادگی کاربر را وارد کنید"
            className="border-2 border-[#CBCBCB] p-2 rounded-md "
          />
        </div>

        <div className="flex flex-col relative">
          <span className="absolute bg-white -top-3 right-2 px-3">ایمیل</span>
          <input
            value={dataSchema.email}
            onChange={(e) => onSetDataSchemaHandler('email', e.target.value)}
            placeholder="نام خانوادگی کاربر را وارد کنید"
            className="border-2 border-[#CBCBCB] p-2 rounded-md "
          />
        </div>

        <div className="flex flex-col relative">
          <span className="absolute bg-white -top-3 right-2 px-3">کد ملی</span>
          <input
            value={dataSchema.national_code}
            onChange={(e) => onSetDataSchemaHandler('national_code', e.target.value)}
            placeholder="کد ملی را وارد کنید"
            className="border-2 border-[#CBCBCB] p-2 rounded-md "
          />
        </div>

        <div className="flex flex-col relative">
          <span className="absolute bg-white -top-3 right-2 px-3">شماره تماس</span>
          <input
            value={dataSchema.phone_number}
            onChange={(e) => onSetDataSchemaHandler('phone_number', e.target.value)}
            placeholder="شماره تلفن را وراد کنید"
            className="border-2 border-[#CBCBCB] p-2 rounded-md "
          />
        </div>

        <div className="flex flex-col relative">
          <span className="absolute bg-white -top-3 right-2 px-3">تاریخ تولد</span>
          <CharityDatePicker
            placeholder={'تاریخ تولد خود را انتخاب کنید'}
            onChangeHandler={onSetDataSchemaHandler}
            value={dataSchema.birthday}
            target={'birthday'}
          />
        </div>

        <div className="flex flex-col gap-y-5 border rounded-md p-2">
          <div className="flex items-center justify-start gap-x-2">
            <label htmlFor="finance_checkbox">نیروی مالی</label>
            <input id="finance_checkbox" type="checkbox" onChange={(e) => onSetCheckboxHandler(e.target.checked, 1)} />
          </div>
          {dataSchema.user_type.includes(1) && (
            <div className="flex flex-col relative">
              <span className="absolute bg-white -top-3 right-2 px-3">مبلغ ماهیانه</span>
              <input
                value={dataSchema.alms}
                onChange={(e) => onSetDataSchemaHandler('alms', e.target.value)}
                placeholder="مبلغ اهدایی ماهیانه را وارد کنید"
                className="border-2 border-[#CBCBCB] p-2 rounded-md "
              />
            </div>
          )}
        </div>

        <div className="flex flex-col gap-y-5 border rounded-md p-2">
          <div className="flex items-center justify-start gap-x-2">
            <label htmlFor="human_checkbox">نیروی انسانی</label>
            <input id="human_checkbox" type="checkbox" onChange={(e) => onSetCheckboxHandler(e.target.checked, 2)} />
          </div>
          {dataSchema.user_type.includes(2) && (
            <div className="flex flex-col relative">
              <span className="absolute bg-white -top-3 right-2 px-3">کمیته کاربر</span>

              <select
                value={dataSchema.committees}
                onChange={(e) => onSetDataSchemaHandler('committees', e.target.value)}
                className="border-2 border-[#CBCBCB] p-2 rounded-md"
              >
                {committes.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        <AddLocationsList dataSchema={dataSchema} onDataSchemaHandler={onSetDataSchemaHandler} />

        <div className="my-2 flex flex-col items-start justify-start w-full">
          <span>مدرک تحصیلی</span>
          <select
            onChange={(e) => onSetDataSchemaHandler('degree', +e.target.value)}
            className="w-full border-2 border-[#9b9b9b] p-2 rounded-md"
          >
            {['زیردیپلم', 'دیپلم', 'لیساسنس', 'فوق لیسانس', 'دکترا'].map((singleDegree, index) => (
              <option value={singleDegree} key={index}>
                {singleDegree}
              </option>
            ))}
          </select>
        </div>

        {searchParams.status === 'edit' ? (
          <button
            onClick={httpEditUser}
            disabled={buttonDisabel}
            className="px-8 py-2 rounded-md bg-blue-700 border-2 border-blue-700 text-white hover:text-blue-700 hover:bg-white duration-200 w-fit mr-auto"
          >
            ویرایش کردن
          </button>
        ) : (
          <button
            onClick={httpCreateNewUser}
            disabled={buttonDisabel}
            className="px-8 py-2 rounded-md bg-blue-700 border-2 border-blue-700 text-white hover:text-blue-700 hover:bg-white duration-200 w-fit mr-auto"
          >
            اضافه کردن
          </button>
        )}
      </div>
    </div>
  );
};

export default AddAndEditUser;
