import useFetch from '../hooks/useFetch';
const endPoint = 'titles/image';

export async function PostNewTitleHttp({ text, body }) {
  const apiCall = await useFetch().postForm(`title/text`, {
    text,
    short_title: body
  });
  return apiCall;
}

export async function GetAllImages() {
  const apiCall = await useFetch().get(`title/images`);
  return apiCall;
}

export async function DeleteSingleImages({ id }) {
  const apiCall = await useFetch().delete(`${endPoint}/${id}`);

  return apiCall;
}

export async function GetSingleImages({ id }) {
  const apiCall = await useFetch().get(`${endPoint}/${id}/edit`);

  return apiCall;
}

export async function CreateNewImages({ status, image }) {
  const formData = new FormData();

  formData.append("status", status? 1:0);
  formData.append("image", image);

  const apiCall = await useFetch().post(`${endPoint}`, formData);

  return apiCall;
}

export async function EditImages({ id, status }) {
  
  const data = {
    "status" : status?1:0,
  }

  const apiCall = await useFetch().put(`${endPoint}/${id}`, data);

  return apiCall;
}

// export async function EditImage({ id , image }){
//   const formData = new FormData();
//   formData.append("image" , image);

//   const apiCall = await useFetch().post(`${endPoint}/image/${id}`);

//   return apiCall

// }

// export async function DeleteImagesBanner({ id }) {
//   const apiCall = await useFetch().delete(`${endPoint}/image/${id}`);

//   return apiCall;
// }

