import { useEffect, useState } from "react";

//react router dom
import { Link, useParams } from "react-router-dom";

//service
import { GetSingleAction } from "../../../../services/action";

//svg
import { ReactComponent as Back } from "../../../../assets/svg/backward.svg";
import { toast } from "react-toastify";
import { ReactComponent as Pdf } from "../../../../assets/svg/pdf.svg";

const SingleAction = () => {
  const { id } = useParams();

  //data
  const [singleAction, setSingleAction] = useState({});

  useEffect(() => {
    httpGetSingleAction();
  }, []);

  const httpGetSingleAction = async () => {
    try {
      const response = await GetSingleAction({ id });

      if (response.status === 200) {
        setSingleAction({
          ...response.data.data,
        });
      } else {
        toast.error("دریافت کمتیه ی تکی ناموفق بود");
      }
    } catch (error) {
      console.log("error in getting single committee : ", error);
    }
  };

  return (
    <div className="flex flex-col mb-10">
      <Link className="self-end" to={"/action"}>
        <button className="flex items-center  gap-2 bg-[#435bf1] text-white -mt-16 w-fit py-3 px-4 rounded-md">
          <Back />
          بازگشت
        </button>
      </Link>
      <div className="flex flex-col gap-8 bg-white p-8 rounded-md">
        <img
          src={singleAction.image}
          alt="banner"
          className="bg-slate-400 w-full h-[350px] rounded-sm object-cover"
        />
        <div>
          <span className="text-[#717171] font-bold px-2">عنوان فعالیت :</span>
          <span className="font-bold text-xl">
            {singleAction.title}
          </span>
        </div>
        <div className="flex flex-col ">
          <span className="text-[#717171] font-bold px-2"> توضیحات :</span>
          <p
            className="leading-10 text-[#353535] font-medium"
            dangerouslySetInnerHTML={{ __html: singleAction.caption }}
          ></p>
        </div>
        <div className="m-10 card bg-white shadow-xl p-5 ">
          <h1 className="font-bold text-xl mb-5 "> فایل پیوستی ها</h1>
          <a
            className="cursor-pointer flex gap-3 items-center hover:text-blue-600"
            title="download" href={singleAction.attachment}
          >
            <Pdf className="w-8 h-8" />
               فایل پیوست.pdf
          </a>
        </div>
      </div>
    </div>
  );
};

export default SingleAction;
