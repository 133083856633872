import React from "react";
import { Link, useLocation } from "react-router-dom";

const MapMenu = ({ header, list, setSidebarOpen }) => {
  const location = useLocation();
  return (
    <div>
      <li className="text-[#C8CAC9] font-medium text-sm pr-5 pb-5 mt-0">{header}</li>
      {list.map(({ Icon, route, title }, index) => (
        <li className="px-4" key={index}>
          <Link
            onClick={() => setSidebarOpen(false)}
            to={route}
            className={`${
              location.pathname.includes(route)
                ? "bg-[#EFF1FE] text-[#4251D3] rounded-md"
                : "text-[#787C7B]"
            } flex gap-x-3 py-3 px-4 items-center font-medium transform transition`}
          >
            <Icon
              className={`${
                location.pathname.includes(route)
                  ? "text-[#4251D3]"
                  : "text-[#787C7B]"
              } w-7`}
            />
            {title}
          </Link>
        </li>
      ))}
    </div>
  );
};

export default MapMenu;
