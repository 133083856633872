import useFetch from '../hooks/useFetch';
const endPoint = 'employees';

export async function GetAllEmployee({ filter }) {
  const apiCall = await useFetch().get(`${endPoint}?${filter}`);
  return apiCall;
}

export async function DeleteSingleEmployee({ id }) {
  const apiCall = await useFetch().delete(`${endPoint}/${id}`);

  return apiCall;
}

export async function GetSingleEmployee({ id }) {
  const apiCall = await useFetch().get(`${endPoint}/${id}`);
  return apiCall;
}

export async function CreateNewEmployee({
  first_name,
  last_name,
  email,
  national_code,
  phone_number,
  employee_category,
  caption,
  birthday,
  image,
}) {
  const formData = new FormData();

  formData.append('first_name', first_name);
  formData.append('last_name', last_name);
  formData.append('employee_category', employee_category);
  formData.append('email', email);
  formData.append('national_code', national_code);
  formData.append('phone_number', phone_number);
  formData.append('caption', caption);
  formData.append('birthday', birthday);
  formData.append('image', image);

  const apiCall = await useFetch().post(`${endPoint}`, formData);

  return apiCall;
}

export async function EditEmployee({
  id,
  first_name,
  last_name,
  email,
  national_code,
  phone_number,
  employee_category,
  caption,
  birthday,
}) {
  const apiCall = await useFetch().put(`${endPoint}/${id}`, {
    first_name,
    last_name,
    email,
    national_code,
    phone_number,
    employee_category,
    caption,
    birthday,
  });

  return apiCall;
}

export async function EditImage({ id, image }) {
  const formData = new FormData();

  formData.append('image', image);
  await useFetch().post(`${endPoint}/image/${id}`, formData);
}

export async function DeleteEmployeeBanner({ id }) {
  const apiCall = await useFetch().delete(`${endPoint}/image/${id}`);

  return apiCall;
}
