import { useState, useEffect, useRef } from 'react';

//react router dom
import { useLocation } from 'react-router-dom';

import { Link } from 'react-router-dom';
import CouncilTableWrapper from '../../../components/common/tableWrapper/index';
import SingleUserCard from '../../../components/page/user/singleUserCard/index';
import { GetAllUser } from '../../../services/user';

//SVG
import { ReactComponent as SearchIcon } from './../../../assets/svg/search.svg';
import useCustomSearchParams from '../../../hooks/useCustomSearchParams';

const tableHeader = [
  {
    title: '#',
    style: 'col-span-1',
  },
  {
    title: 'نام',
    style: 'col-span-2',
  },
  {
    title: 'نام خانوادگی',
    style: 'col-span-1',
  },
  {
    title: 'کد ملی',
    style: 'col-span-2',
  },
  {
    title: 'شماره تماس',
    style: 'col-span-1',
  },
  {
    title: 'نوع نیرو',
    style: 'col-span-2',
  },
];

export default function User() {
  const searchContainer = useRef();

  const location = useLocation();

  const [users, setUsers] = useState([]);
  const [meta, setMeta] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  //custome hooks
  const [searchParams, setSearchParams] = useCustomSearchParams();

  useEffect(() => {
    //check if have searchParams
    if (searchParams.search) {
      //add value of search to search Container to show user
      searchContainer.current.value = searchParams.search;
    }

    //on search for course after filtering data,
    //check if we have any search params

    //checking that a request has not already been made

    if (isLoading === false) {
      httpGetCourseList(location.search.substring(1));
    }
  }, [location]);

  const httpGetCourseList = async (filter = '') => {
    setIsLoading(true);
    try {
      const response = await GetAllUser({ filter });

      if (response.status === 200) {
        setUsers([...response.data.data]);
        setMeta({
          ...response.data.meta,
        });
      }
    } catch (error) {
      console.log('error in fetching commite list : ', error);
    }
    setIsLoading(false);
  };

  const onSetSearchHandler = () => {
    setSearchParams({
      search: searchContainer.current.value,
    });
  };

  const generateTable = () => {
    return users.length ? (
      <div className="bg-white px-2 rounded-b-md">
        {users.map((singleUser, index) => (
          <SingleUserCard key={index} index={index} singleUser={singleUser} />
        ))}
      </div>
    ) : (
      <span className="bg-orange-300 w-fit self-center p-5 rounded-md">متاسفانه هیچ داده ای یافت نشد!</span>
    );
  };

  return (
    <div>
      <div className="flex flex-col items-start justify-center gap-10 relative">
        <div className="flex items-center justify-end w-full absolute -top-16 left-10">
          {/* <span className="font-medium text-3xl">لیست اعضا</span> */}
          <Link
            to={'/users/changeuser?status=add'}
            className="bg-[#0172DE] text-white border-2 border-[#0172DE] duration-200 px-3 py-2 rounded-md"
          >
            اضافه کردن عضو جدید
          </Link>
        </div>
        <div className="bg-white p-4 rounded-md w-full my-5">
          <div className="flex items-center w-full border-2 border-[#CBCBCB] rounded-md p-1 pl-5 pr-2 py-2">
            <input
              ref={searchContainer}
              className="w-full outline-none text-lg placeholder:text-base placeholder:text-[#CBCBCB]"
              placeholder="کمیته مورد نظر خود را جستجو کنید."
            />
            <button onClick={onSetSearchHandler}>
              <SearchIcon className="text-[#717171]" />
            </button>
          </div>
        </div>
      </div>
      <CouncilTableWrapper meta={meta} tableHeader={tableHeader} minSize="min-w-[900px]">
        {generateTable()}
      </CouncilTableWrapper>
    </div>
  );
}
