import { useState } from 'react';

import CouncilTextEditor from '../../../components/common/charityTextEditor';
import CharityDropFile from '../../../components/common/dropFile';

import { PostNewTitleHttp } from '../../../services/title';
import { toast } from 'react-toastify';

const Title = () => {
  const [text, setText] = useState('');
  const [body , setBody] = useState('');
  const [image, setImage] = useState();

  const PostNewTitle = async () => {
    try {
      const response = await PostNewTitleHttp({ text : text , body : body });

      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error('مشکلی در ایجاد متن بوجود آمده');
      }
    } catch (error) {
      console.log('error in post new title : ', error);
    }
  };

  const onSetDataSchemaHandler = (target, value) => {
    setBody(value);
  };


  return (
    <div className="flex flex-col gap-y-5 pb-5">
      <span className="text-xl font-medium">تنظیم بخش های عنوان </span>

      <div className="flex flex-col">
        <span className="text-lg">عنوان کوتاه سایت</span>
        <textarea
          value={body}
          onChange={(e) => onSetDataSchemaHandler("body", e.target.value)}
          placeholder="یک توضیح کوتاه برای دوره وارد کنید"
          className="min-h-[100px] max-h-[300px] border-2 border-[#CBCBCB] p-2 rounded-md"
        />
        <span className="text-lg">عنوان سایت</span>
        <CouncilTextEditor defaultValue={''} value={text} title={''} getValue={(title, value) => setText(value)} />
        <button
          onClick={PostNewTitle}
          className="bg-blue-700 px-7 py-2 rounded-md text-white font-medium w-fit mr-auto mt-2"
        >
          ثبت
        </button>
      </div>

      {/* <div className="flex flex-col">
        <span className="text-lg">بنر سایت</span>
        <div>
          <CharityDropFile
            data={image}
            setData={(e) => setImage(e)}
            acceptableType={'image/png, image/gif, image/jpeg'}
            isArray={false}
            isImage={true}
          />
        </div>
        <button
          onClick={PostNewBanner}
          className="bg-blue-700 px-7 py-2 rounded-md text-white font-medium w-fit mr-auto mt-2"
        >
          ثبت
        </button>
      </div> */}
    </div>
  );
};

export default Title;
