import { useState } from 'react';

import { CheckPhoneNumber } from '../../../../services/auth';

import { toast } from 'react-toastify';

function PhoneNumberContainer({ onChangeStatusHandler }) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState(false);

  const onChangePhonNumberHandler = (value) => {
    if (!/^09\d{9}$/.test(value)) {
      setError(true);
    } else {
      setError(false);
    }

    setPhoneNumber(value);
  };

  const onSendPhoneNumberHandler = async () => {
    //check if there is error
    if (error) return;

    //check the length of phone number
    if (phoneNumber.trim().length === 0) {
      //there is no phone number, set error
      setError(true);
      return;
    }

    try {
      const response = await CheckPhoneNumber({ phoneNumber });

      console.log('response :', response);

      //check response status
      if (response.status === 200) {
        //code sent correctly , go to next step
        onChangeStatusHandler(1, phoneNumber);
      } else {
        toast.error('ارسال پیامک با مشکل مواجه شد');
      }
    } catch (error) {
      console.log('error : ', error);
    }
  };

  return (
    <>
      <div className="my-5 flex flex-col gap-2 w-full">
        <div className="flex flex-col items-start gap-1 w-full">
          <label className="font-medium">شماره تلفن</label>
          <input
            dir="ltr"
            value={phoneNumber}
            onChange={(e) => onChangePhonNumberHandler(e.target.value)}
            placeholder="مثال : 09123456789"
            className={`placeholder:text-sm text-lg border-2 rounded-md outline-none px-3 py-2 w-full ${
              error ? 'border-red-700' : ''
            }`}
          />
          {error && <span className="text-sm text-red-700">لطفا شماره تلفن را به درستی وارد کنید</span>}
        </div>
      </div>
      <button
        onClick={onSendPhoneNumberHandler}
        className="w-full py-2 bg-blue-700 border-blue-700 border-2 text-white hover:text-blue-700 hover:bg-white duration-200 rounded-md"
      >
        ثبت
      </button>
      <button className="text-blue-700 text-sm font-medium" onClick={() => onChangeStatusHandler(2)}>
        ورود با رمز عبور
      </button>
    </>
  );
}

export default PhoneNumberContainer;
