import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { useCookies } from 'react-cookie';

import Login from './pages/auth';

import Layout from './components/common/layout';
import Dashboard from './pages/mainMenu/dashboard/index';

import CommitesList from './pages/mainMenu/commites';
import SingleCommite from './pages/mainMenu/commites/singleCommite';
import AddNewCommite from './pages/mainMenu/commites/addNewCommite';

import EducationalCourses from './pages/mainMenu/educationalCourses/index';
import SingleCourse from './pages/mainMenu/educationalCourses/singleEducationalCourse';
import AddNewCourse from './pages/mainMenu/educationalCourses/addCourse';

import Users from './pages/mainMenu/users/index';
import MessagePanel from './pages/quickAccess/messagePanel/index';

import Title from './pages/quickAccess/title';

import News from './pages/quickAccess/news/index';
import SingleNews from './pages/quickAccess/news/singleNews';
import AddNewNews from './pages/quickAccess/news/addNewNews';

import Settings from './pages/accountManagement/settings/index';
import CourseDetail from './pages/mainMenu/educationalCourses/courseDetail';
import AddAndEditUser from './pages/mainMenu/users/addAndEditUser';

import ActionList from './pages/mainMenu/Actions';
import AddNewAction from './pages/mainMenu/Actions/addNewAction';
import SingleAction from './pages/mainMenu/Actions/singleAction';

import EmployeesList from './pages/mainMenu/Employees';
import AddNewEmployee from './pages/mainMenu/Employees/addNewEmployee';
import SingleEmployee from './pages/mainMenu/Employees/singleEmployee';


import ImageTitle from './pages/quickAccess/TitleImages';
import AddNewImage from './pages/quickAccess/TitleImages/addNewImage';
// import AddNewAction from "./pages/mainMenu/Actions/addNewAction";
// import SingleAction from "./pages/mainMenu/Actions/singleAction";

const App = () => {
    const [cookies] = useCookies(['token']);
    const { pathname } = useLocation();
    const navigation = useNavigate();

    useEffect(() => {
        if (pathname !== '/' && cookies.token === undefined) {
            //go to login page
            navigation('/');
        }

        // redirect to top
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [pathname]);

    return (< >
        < Routes >
            < Route element={< Login />} path="/" />
            < Route element={< Layout />} path="/" >
                <Route element={< Dashboard />}
                    path="/dashboard" />

                { /* commite list */}
                <Route path="/commites" element={< CommitesList />} />
                <Route path="/commites/detail/:id" element={< SingleCommite />} />
                <Route path="/commites/newcommite" element={< AddNewCommite />} />

                { /* Action list */}
                <Route path="/action" element={< ActionList />} />
                <Route path="/action/detail/:id" element={< SingleAction />} />
                <Route path="/action / newaction " element={< AddNewAction />} />

                { /* educational course */}
                <Route element={< EmployeesList />} path="/employees" />
                <Route element={< SingleEmployee />} path="/employees/detail/:id" />
                <Route element={< AddNewEmployee />} path="/employees/newemployee" />

                { /* employees section */}
                <Route element={< EducationalCourses />} path="/educationalCourses" />
                <Route element={< SingleCourse />} path="/educationalCourses/detail/:id" />
                <Route element={< AddNewCourse />} path="/educationalCourses/newcourse" />

                { /* users */}
                <Route element={< Users />} path="/users" />
                <Route element={< AddAndEditUser />} path="/users/changeuser" />
                <Route element={< MessagePanel />} path="/messagePanel" />

                { /* news */}
                <Route element={< News />} path="/news" />
                <Route element={< SingleNews />} path="/news/detail/:id" />
                <Route element={< AddNewNews />} path="/news/newnews" />

                { /* title */}
                <Route element={< Title />} path="/title/text" />
                <Route element={< ImageTitle />} path="/title/images" />
                <Route element={< SingleNews />} path="/image/detail/:id" />
                <Route element={< AddNewImage />} path="/title/newimage" />


                <Route element={< Settings />} path="/settings" />
            </Route> </Routes >
    </>
    );
};
export default App;