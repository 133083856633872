import React, { useState } from "react";

//react toastify
import { toast } from "react-toastify";

//react router dom
import { useNavigate } from "react-router-dom";

//service
import { DeleteSingleNews } from "../../../../services/news";

//component
import ModalWrapper from "../../../common/modalWrapper";
import LoadingBtn from "../../../common/loadingBtn";

const DeleteSingleNewsModal = ({
  closeModalHandler,
  name,
  id,
  isShowModal,
}) => {
  //navigate
  const navigate = useNavigate();

  //data
  const [isLoading, setIsLoading] = useState(false);

  const httpDeleteNewsCommittee = async () => {
    setIsLoading(true);
    try {
      const response = await DeleteSingleNews({ id });

      //check response status
      if (response.status === 200) {
        //committee deleted successfully
        navigate(0);
      } else {
        toast.error("حذف خبر ناموفق بود");
      }
    } catch (error) {
      console.log("error in delete single news : ", error);
    }
    setIsLoading(false);
  };

  return (
    <ModalWrapper isShowedModal={isShowModal} onCloseModal={closeModalHandler}>
      <div className="flex flex-col items-center justify-center gap-7">
        <span className="text-xl font-medium text-[#353535]">
          آیا از حذف خبر {name} مطمئن هستید؟
        </span>

        <div className="flex items-center justify-between w-full">
          <LoadingBtn
            isLoading={isLoading}
            onClickHandler={httpDeleteNewsCommittee}
            btnClass="border border-[#ED2E2E] text-white bg-[#ED2E2E] rounded-xl"
          >
            حذف
          </LoadingBtn>
          <button
            onClick={closeModalHandler}
            className="border border-[#ED2E2E] text-[#ED2E2E] px-7 p-2 rounded-xl font-medium"
          >
            انصراف
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default DeleteSingleNewsModal;
