import { useState } from "react";
import Modal from "../../../common/modalWrapper";

//react router dom
import { Link } from "react-router-dom";

//component
import DeleteSingleCourseModal from "../deleteSingleCourseModal";

export default function SingleCourseCard({ index, singleCourse, lastIndex }) {
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  return (
    <>
      <tr className="grid grid-cols-12 w-full items-center text-center bg-white p-4 border-b font-medium text-[#74787C]">
        <td className="col-span-1">{index + 1}</td>
        <td className="col-span-3">{singleCourse.title}</td>
        <td className="col-span-2">{singleCourse.author}</td>
        <td className="col-span-1">{singleCourse.status}</td>
        <td className="col-span-2">{singleCourse.participants}</td>
        <td className="col-span-3 flex items-center justify-center gap-2 flex-wrap">
          <button
            onClick={() => setIsShowDeleteModal(true)}
            className="duration-200 px-4 py-2 hover:border-[#ED2E2E] hover:text-[#ED2E2E] text-[#717171] text-sm border-2 rounded-md "
          >
            حذف
          </button>
          <Link
            to={`/educationalCourses/newcourse?status=edit&id=${singleCourse.id}`}
            className="duration-200 px-4 py-2 hover:border-[#F4B740] hover:text-[#F4B740] text-[#717171] text-sm border-2 rounded-md"
          >
            ویرایش
          </Link>
          <Link
            to={`/educationalCourses/detail/${singleCourse.id}`}
            className="duration-200 px-4 py-2 hover:border-[#00BA88] hover:text-[#00BA88] text-[#717171] text-sm border-2 rounded-md"
          >
            جزئیات
          </Link>
        </td>
      </tr>
      {isShowDeleteModal && (
        <DeleteSingleCourseModal
          id={singleCourse.id}
          name={singleCourse.name}
          isShowModal={isShowDeleteModal}
          closeModalHandler={() => setIsShowDeleteModal(false)}
        />
      )}
    </>
  );
}
