import React, { useEffect, useState } from "react";

//react router dom
import { Link, useNavigate } from "react-router-dom";
import useCustomSearchParams from "../../../../hooks/useCustomSearchParams";
//service
import {
  CreateNewCommittee,
  GetSingleCommittee,
  EditCommittee,
  DeleteCommitteeBanner,
  EditImage,
} from "../../../../services/committee";

//component
import CharityTextEditor from "../../../../components/common/charityTextEditor";
import CharityDropFile from "../../../../components/common/dropFile";

//SVG
import { ReactComponent as Back } from "../../../../assets/svg/backward.svg";
import { toast } from "react-toastify";

const AddNewCommite = () => {
  //navigate
  const navigate = useNavigate();

  const [searchParams] = useCustomSearchParams();

  //data
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [dataSchema, setDataSchema] = useState({
    name: "",
    short: "",
    caption: "",
  });

  const [image, setImage] = useState();

  useEffect(() => {
    //check if we are in edit mode
    if (searchParams.status === "edit") {
      httpGetSingleCommittee();
    }
  }, []);

  const onSetDataSchemaHandler = (target, value) => {
    setDataSchema((prevState) => ({
      ...prevState,
      [target]: value,
    }));
  };

  const httpGetSingleCommittee = async () => {
    try {
      const response = await GetSingleCommittee({ id: searchParams.id });

      if (response.status === 200) {
        setDataSchema({
          ...response.data.data,
        });
      }
    } catch (error) {
      console.log("error in get signle committee : ", error);
    }
  };

  const httpCreateNewCommittee = async () => {

    setButtonDisabled(false)
    try {
      const response = await CreateNewCommittee({
        name: dataSchema.name,
        caption: dataSchema.caption,
        image,
      });

      if (response.status === 200) {
        navigate("/commites");
      } else {
        toast.error("اضافه کردن کمیته ناموفق بود");
      }
    } catch (error) {
      console.log("error in create new committee : ", error);
    }
  };

  const httpEditCommittee = async () => {
    try {
      setButtonDisabled(true)
      const response = await EditCommittee({
        id: searchParams.id,
        name: dataSchema.name,
        caption: dataSchema.caption,
      });

      if (image !== undefined) {
        await EditImage({
          id: searchParams.id,
          image
        })
      }
      if (response.status === 200) {
        setButtonDisabled(false)
        navigate("/commites");
      } else {
        setButtonDisabled(false)
        toast.error("ویرایش کردن کمیته ناموفق بود");
      }
    } catch (error) {
      console.log("error in edit new committee : ", error);
    }
  };

  const httpDeleteBanner = async () => {
    try {
      setButtonDisabled(true)
      const response = await DeleteCommitteeBanner({ id: searchParams.id });

      if (response.status === 200) {
        setButtonDisabled(false)
        toast.success("بنر خبر با موفقیت حذف شد");
        navigate(0);
      } else {
        setButtonDisabled(false)
        toast.error("حذف بنر موفقیت آمیز نبود");
      }
    } catch (error) {
      console.log("error in delete Banner : ", error);
    }
  };

  return (
    <div className="flex flex-col">
      <Link className="self-end" to={"/commites"}>
        <button className="flex items-center  gap-2 bg-[#435bf1] text-white -mt-16 w-fit py-3 px-4 rounded-md">
          <Back />
          بازگشت
        </button>
      </Link>
      <div className="flex flex-col gap-8 bg-white p-8 rounded-md">
        <span className="font-bold">ایجاد کمیته جدید</span>
        <div className="flex flex-col relative">
          <span className="absolute bg-white -top-3 right-2 px-3">عنوان</span>
          <input
            value={dataSchema.name}
            onChange={(e) => onSetDataSchemaHandler("name", e.target.value)}
            placeholder="عنوان کمیته جدید رو اینجا بنویس!"
            className="border-2 border-[#CBCBCB] p-2 rounded-md "
          />
        </div>
        <div className="flex flex-col relative">
          <span className="absolute bg-white -top-3 right-2 px-3">
            توضیح کوتاه برای کمیته
          </span>
          <input
            value={dataSchema.short}
            onChange={(e) =>
              onSetDataSchemaHandler("short", e.target.value)
            }
            placeholder="یک توضیح کوتاه برای کمیته وارد کنید"
            className="border-2 border-[#CBCBCB] p-2 rounded-md "
          />
        </div>
        <div className="flex flex-col relative">
          <CharityTextEditor
            title={"caption"}
            value={dataSchema.caption}
            getValue={onSetDataSchemaHandler}
          />
        </div>

        {dataSchema.image ? (
          <div>
            <button
              onClick={httpDeleteBanner}
              className="px-7 py-2 text-white font-medium bg-red-700 rounded-md mb-5"
            >
              حذف عکس
            </button>
            <img
              className="h-[300px] w-full object-contain"
              src={dataSchema.image}
              alt="banner"
            />
          </div>
        ) : (
          <CharityDropFile
            data={image}
            setData={(e) => setImage(e)}
            acceptableType={"image/png, image/gif, image/jpeg"}
            isArray={false}
            isImage={true}
          />
        )}

        {searchParams.status === "edit" ? (
          <button
            onClick={httpEditCommittee}
            disabled={buttonDisabled}
            className="px-8 py-2 rounded-md bg-blue-700 border-2 border-blue-700 text-white hover:text-blue-700 hover:bg-white duration-200 w-fit mr-auto"
          >
            ویرایش کردن
          </button>
        ) : (
          <button
            onClick={httpCreateNewCommittee}
            disabled={buttonDisabled}
            className="px-8 py-2 rounded-md bg-blue-700 border-2 border-blue-700 text-white hover:text-blue-700 hover:bg-white duration-200 w-fit mr-auto"
          >
            اضافه کردن
          </button>
        )}
      </div>
    </div>
  );
};

export default AddNewCommite;
