import TablePagination from './pagination';
export default function CouncilTableWrapper({ minSize, tableHeader, children, meta }) {
  const genarateMeta = () => {
    if (meta !== undefined) {
      if (meta.total_records === 0) {
        return <span className="text-lg font-semibold text-red-700">داده ای برای نمایش وجود ندارد</span>;
      } else if (Object.keys(meta).length !== 0) {
        return <TablePagination meta={meta} />;
      }
    }
  };

  return (
    <div className="w-full">
      <div className="overflow-x-auto w-full mb-8">
        <div className={`${minSize}`}>
          <table className="flex flex-col w-full whitespace-nowrap">
            <thead className="text-lg font-semibold">
              <tr className="grid grid-cols-12 w-full bg-white p-4 border-b rounded-t-lg">
                {tableHeader.map((header, headerIndex) => (
                  <th key={headerIndex} className={`${header.style}`}>
                    {header.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="flex flex-col">{children}</tbody>
          </table>
        </div>
      </div>
      {genarateMeta()}
    </div>
  );
}
