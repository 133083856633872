import React, { useState } from "react";

//react router dom
import { useNavigate } from "react-router-dom";

//service
import { DeleteSingleEmployee } from "../../../../services/employee";

//component
import ModalWrapper from "../../../common/modalWrapper";
import LoadingBtn from "../../../common/loadingBtn";
import { toast } from "react-toastify";

const DeleteSingleEmployeeModal = ({
  closeModalHandler,
  name,
  id,
  isShowModal,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  //navigate
  const navigate = useNavigate();

  //data
  const [isLoading, setIsLoading] = useState(false);

  const httpDeleteSingleAction = async () => {
    setIsLoading(true);
    try {
      setDisableButton(true)
      const response = await DeleteSingleEmployee({ id });

      //check response status
      if (response.status === 200) {
      setDisableButton(false)
        //committee deleted successfully
        navigate(0);
      } else {
      setDisableButton(false)
        toast.error("حذف مسول ناموفق بود");
      }
    } catch (error) {
      console.log("error in delete single committee : ", error);
    }
    setIsLoading(false);
  };

  return (
    <ModalWrapper isShowedModal={isShowModal} onCloseModal={closeModalHandler}>
      <div className="flex flex-col items-center justify-center gap-7">
        <span className="text-xl font-medium text-[#353535]">
          آیا از حذف مسول {name} مطمئن هستید؟
        </span>

        <div className="flex items-center justify-between w-full">
          <LoadingBtn
            isLoading={isLoading}
            onClickHandler={httpDeleteSingleAction}
            btnClass="border border-[#ED2E2E] text-white bg-[#ED2E2E] rounded-xl"
          >
            حذف
          </LoadingBtn>
          <button
            onClick={closeModalHandler}
            disabled={disableButton}
            className="border border-[#ED2E2E] text-[#ED2E2E] px-7 p-2 rounded-xl font-medium"
          >
            انصراف
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default DeleteSingleEmployeeModal;
