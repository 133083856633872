import { useState } from 'react';

import PhoneNumberContainer from '../../components/page/auth/PhoneNumberContainer';
import VerifyPhone from '../../components/page/auth/VerifyPhoneNumber';
import LoginForm from '../../components/page/auth/LoginForm';

const Login = () => {
  const [status, setStatus] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState('');

  const onChangeStatusHandler = (newStatus, _phoneNumber) => {
    setStatus(newStatus);

    //check if there is phone number
    if (_phoneNumber) {
      //set phone number
      setPhoneNumber(_phoneNumber);
    }
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-2 bg-white rounded-xl shadow-md w-full max-w-lg xl:w-1/3 p-5 mx-5">
        <span className="text-xl font-medium">ورود به پنل ادمین</span>
        <span className="text-sm text-gray-400">پنل ادمین سایت شهید حججی</span>

        {status === 0 ? (
          <PhoneNumberContainer onChangeStatusHandler={onChangeStatusHandler} />
        ) : status === 1 ? (
          <VerifyPhone userPhonNumber={phoneNumber} onChangeStatusHandler={onChangeStatusHandler} />
        ) : (
          <LoginForm onChangeStatusHandler={onChangeStatusHandler} />
        )}
      </div>
    </div>
  );
};

export default Login;
