import useFetch from "../hooks/useFetch";
const endPoint = "activities";

export async function GetAllAction({ filter }) {
  const apiCall = await useFetch().get(`${endPoint}?${filter}`);
  return apiCall;
}

export async function DeleteSingleAction({ id }) {
  const apiCall = await useFetch().delete(`${endPoint}/${id}`);

  return apiCall;
}

export async function GetSingleAction({ id }) {
  const apiCall = await useFetch().get(`${endPoint}/${id}`);

  return apiCall;
}

export async function CreateNewAction({
  short,
  title,
  caption,
  image,
  file
}) {
  const formData = new FormData();

  formData.append("title", title);
  formData.append("caption", caption === undefined ? null : caption);
  formData.append("image", image);
  formData.append("short", short === undefined ? null : short);
  formData.append("attachment", file);

  const apiCall = await useFetch().post(`${endPoint}`, formData);

  return apiCall;
}

export async function EditAction({
  id,
  short,
  title,
  caption,
}) {

  const data = {
    "title": title === undefined ? null : title,
    "caption": caption === undefined ? null : caption,
    "short": short === undefined ? null : short,
  }

  const apiCall = await useFetch().put(`${endPoint}/${id}`, data);

  return apiCall;
}

export async function EditImage({ id, image }) {

  const formData = new FormData();

  formData.append("image", image);
  await useFetch().post(`${endPoint}/image/${id}`, formData);
}

export async function EditFile({ id, file }) {

  const formData = new FormData();

  formData.append("file", file);
  await useFetch().post(`${endPoint}/file/${id}`, formData);
}

export async function DeleteActionBanner({ id }) {
  const apiCall = await useFetch().delete(`${endPoint}/image/${id}`);

  return apiCall;
}

export async function DeleteActionFile({ id }) {
  const apiCall = await useFetch().delete(`${endPoint}/file/${id}`);

  return apiCall;
}
