const InfoMassage = () => {
  return (
    <div className="flex justify-center gap-6 -mt-20 ">
      <div className="flex flex-col gap-5 bg-white rounded-md px-6 py-4 w-full">
        <span className="font-bold text-xl">همه</span>
        <span className="text-xs text-[#787C7B]">
          تعداد کل پیامک های ارسال شده
        </span>
        <span className="font-bold flex items-center justify-end">
          <span className="text-4xl">100</span> پیامک
        </span>
      </div>
      <div className="flex flex-col gap-5 bg-white rounded-md px-6 py-4 w-full">
        <span className="font-bold text-xl">نیروی مالی</span>
        <span className="text-xs text-[#787C7B]">
          تعداد پیامک های ارسال شده به نیروی مالی
        </span>
        <span className="font-bold flex items-center justify-end">
          <span className="text-4xl">100</span> پیامک
        </span>
      </div>
      <div className="flex flex-col gap-5 bg-white rounded-md px-6 py-4 w-full">
        <span className="font-bold text-xl">نیروی انسانی</span>
        <span className="text-xs text-[#787C7B]">
          تعداد پیامک های ارسال شده به نیروی انسانی
        </span>
        <span className="font-bold flex items-center justify-end">
          <span className="text-4xl">100</span> پیامک
        </span>
      </div>
    </div>
  );
};

export default InfoMassage;
