import { useEffect, useState } from "react";

//service
import {
  GetPhoneNumber,
  CreatePhoneNumberMessage,
} from "../../../../services/sms";

//SVG
import { ReactComponent as Arrow } from "../../../../assets/svg/arrow-down.svg";
import { ReactComponent as Tick } from "./../../../../assets/svg/tick.svg";
import { toast } from "react-toastify";

const SendMassage = () => {
  const [phoneNumber, setPhoneNumber] = useState([]);
  const [message, setMessage] = useState("");
  const [listNumber, setListNumber] = useState([]);

  useEffect(() => {
    httpGetPhoneNumberList();
  }, []);

  const httpGetPhoneNumberList = async () => {
    try {
      const response = await GetPhoneNumber();

      if (response.status === 200) {
        setPhoneNumber([...response.data.data]);
      }
    } catch (error) {
      console.log("error in getting phone number : ", error);
    }
  };

  const addNumber = (value) => {
    const index = listNumber.indexOf(value);

    if (index < 0) {
      setListNumber([value, ...listNumber]);
    } else {
      //create helper list
      let helperList = listNumber;

      //slice selected index from the list
      helperList.splice(index, 1);

      //add filtred list to selected list
      setListNumber([...helperList]);
    }
  };

  const httpCreateNewMessage = async () => {
    // if (listNumber.length === 0) {
    //   toast.error("حداقل یک شماره باید انتخاب کنید");
    //   return;
    // }

    let phoneInString = listNumber.join(",");

    try {
      const response = await CreatePhoneNumberMessage({
        body: message,
        phone: phoneInString,
      });

      if (response.status === 200) {
        toast.success("پیام با موفقیت ارسال شد");
      } else {
        toast.error("ارسال پیام ناموفق بود");
      }

      console.log("response : ", response);
    } catch (error) {
      console.log("error in create message : ", error);
    }
  };

  return (
    <div className="flex flex-col gap-5 bg-white py-6 px-8 m-10 rounded-md w-11/12">
      <span className="font-bold">ارسال پیامک</span>
      <div className="group flex flex-col gap-2 z-20 relative">
        <div
          className={`flex gap-2 items-center cursor-pointer ${
            listNumber.length ? "justify-end" : "justify-between"
          } border-2 rounded-lg mt-1 sm:h-8 w-2/4 h-10 py-5 px-3 sm:text-base text-sm text-[#9F9F9F]`}
        >
          <div className="flex overflow-hidden">
            {listNumber.length ? (
              listNumber.map((item, index) => (
                <span key={index}>
                  {item}
                  {index !== listNumber.length - 1 ? "/" : ""}
                </span>
              ))
            ) : (
              <span className="">شماره های خود را انتخاب کنید</span>
            )}
          </div>
          <Arrow className={`group-hover:rotate-180 duration-300 `} />
        </div>
        <div
          className={`hidden group-hover:flex flex-col duration-300 w-fit bg-white absolute top-12 border-2 rounded-md`}
        >
          {phoneNumber.map((item, index) => (
            <div
              onClick={() => addNumber(item)}
              className="border-b px-8 py-1 flex items-center justify-between gap-3 cursor-pointer hover:bg-gray-400 hover:text-white"
              key={index}
            >
              {listNumber.includes(item) && <Tick />}
              <spna>{item}</spna>
            </div>
          ))}
        </div>
      </div>
      <span className="text-[#6B6B6B]">
        {listNumber.length} شماره انتخاب شده است
      </span>

      <div className="border rounded-md relative">
        <textarea
          value={message}
          style={{ resize: "none" }}
          onChange={(e) => setMessage(e.target.value)}
          className="w-full outline-none h-[250px] px-3 py-5"
        />
        <span className="absolute w-[50px] bottom-0 left-0 text-[#AFAFAF]">
          320 /{message.split(/\s+/).length}
        </span>
      </div>

      <button
        onClick={httpCreateNewMessage}
        className="bg-[#435af1] hover:text-[#435af1] hover:bg-white border-2 border-[#435af1] w-fit self-end py-2 px-7 rounded-md text-white font-medium duration-200"
      >
        ارسال پیامک
      </button>
    </div>
  );
};

export default SendMassage;
