import React from "react";

//SVG
import { ReactComponent as UserProf } from "./../../../assets/svg/userProf.svg";
import { ReactComponent as More } from "./../../../assets/svg/more.svg";

const UserProfile = () => {
  return (
    <div className="flex items-center pr-5 gap-x-5 mt-14">
      <UserProf />
      <div>
        <div className="font-extrabold text-lg pl-7"></div>
        <div className="font-normal text-[#BCC0C3] text-xs"></div>
      </div>
      <More />
    </div>
  );
};

export default UserProfile;
