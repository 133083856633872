import React from "react";
import Setting from "../../../components/page/setting";
import PersonalInformation from "../../../components/page/setting/personalinformation";

const Settings = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <Setting />
    </div>
  );
};

export default Settings;
