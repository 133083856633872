import { useState } from 'react';
import { Link } from 'react-router-dom';

//component
import DeleteSingleEmployeeModal from '../deleteSingleEmployeeModel';

const SingleEmployeeCard = ({ singleEmployee, index, lastIndex }) => {
  console.log(singleEmployee);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  return (
    <>
      <div
        className={`grid grid-cols-12 gap-5 w-full ${
          index !== lastIndex ? 'border-b' : ''
        } text-center justify-items-center bg-white py-3 items-center  my-2 font-medium text-[#74787C]`}
      >
        <span className="col-span-1 text-center">{index + 1}</span>
        <img
          className="col-span-2 bg-slate-500 w-3/4 h-14 rounded-md object-cover"
          src={singleEmployee.image}
          alt="banner"
        />
        <span className="col-span-1 truncate w-full">{singleEmployee.first_name}</span>
        <span className="col-span-2 truncate w-full">{singleEmployee.last_name}</span>
        <span className="col-span-2 truncate w-full">{singleEmployee.phone_number}</span>
        <span className="col-span-1 truncate w-full">{singleEmployee.national_code}</span>
        <div className="col-span-3 flex items-center gap-2 justify-center flex-wrap">
          <button
            onClick={() => setIsShowDeleteModal(true)}
            className="duration-200 px-4 py-2 hover:border-[#ED2E2E] hover:text-[#ED2E2E] text-[#717171] text-sm border-2 rounded-md "
          >
            حذف
          </button>
          <Link
            to={`/employees/newemployee?status=edit&id=${singleEmployee.id}`}
            className="duration-200 px-4 py-2 hover:border-[#F4B740] hover:text-[#F4B740] text-[#717171] text-sm border-2 rounded-md"
          >
            ویرایش
          </Link>
          <Link to={`/employees/detail/${singleEmployee.id}`}>
            <button className="duration-200 px-4 py-2 hover:border-[#00BA88] hover:text-[#00BA88] text-[#717171] text-sm border-2 rounded-md">
              جزئیات
            </button>
          </Link>
        </div>
      </div>
      <div>
        {isShowDeleteModal && (
          <DeleteSingleEmployeeModal
            isShowModal={isShowDeleteModal}
            id={singleEmployee.id}
            name={singleEmployee.name}
            closeModalHandler={() => setIsShowDeleteModal(false)}
          />
        )}
      </div>
    </>
  );
};

export default SingleEmployeeCard;
